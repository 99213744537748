import { Component, Input, OnInit } from '@angular/core';
import { tabSlider } from '../data';

@Component({
  selector: 'molla-top-product',
  templateUrl: './top-product.component.html',
  styleUrls: ['./top-product.component.scss']
})
export class TopProductComponent implements OnInit {

  @Input() products = [];
  @Input() otherproducts = [];
	@Input() loaded = false;
  sliderOption = tabSlider;
  constructor() { }

  ngOnInit(): void {
  }

}
