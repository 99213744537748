import { Component, OnInit } from '@angular/core';
import { SaleService } from '../../services/Sale.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'molla-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
  private subscr: Subscription;
  orderList = [];
  userCheck: any;
  expanded = {};

  constructor(public saleService: SaleService, private auth: AuthenticationService,) {
    this.subscr = this.auth.currentUser.subscribe(res => {
      this.userCheck = res;
      this.getOrders(this.userCheck?.id)
    })

  }
  ngOnDestroy(): void {
    this.subscr.unsubscribe();
  }
  ngOnInit(): void {

  }
  toggle(id: number) {
    this.expanded[id] = !this.expanded[id];
  }
  getOrders(id) {
    this.saleService.getOrderByUser(id).subscribe(res => {
      if (res.successFlag) {
        this.orderList = [];

        // Assuming you have the API response stored in a variable called `apiData`
        const groupedData = res.data.reduce((acc, curr: any) => {
          const existingItem = acc.find(item => item.id === curr.id);
          if (existingItem) {
            existingItem.orederDetails.push({
              id: curr.id,
              itemInfoId: curr.itemInfoId,
              categoryName: curr.categoryName,
              categoryId: curr.categoryId,
              itemId: curr.itemId,
              itemName: curr.itemName,
              purchasePricePerItem: curr.purchasePricePerItem,
              purchasePrice: curr.purchasePrice,
              salePrice: curr.salePrice,
              salePricePerItem: curr.salePricePerItem,
              qty: curr.qty,
              amount: curr.amount,
              itemModalName: curr.itemModalName,
              itemModelId: curr.itemModelId,
              whereHouseId: curr.whereHouseId,
              whereHouseName: curr.whereHouseName,
              departmentId: curr.departmentId,
              departmentName: curr.departmentName,
              unitId: curr.unitId,
              sizeId: curr.sizeId,
              imagePath: environment.productImgPath + curr.imagePath,
              subCategoryId: curr.subCategoryId,
              unitName: curr.unitName,
              sizeName: curr.sizeName,
              subCategoryName: curr.subCategoryName
            });
          } else {
            acc.push({
              id: curr.id,
              firstName: this.userCheck.firstName,
              description: curr.description,
              deliveryName: curr.deliveryName,
              addressLine1: curr.addressLine1,
              addressLine2: curr.addressLine2,
              county: curr.county,
              town: curr.town,
              postCode: curr.postCode,
              phoneNo: curr.phoneNo,
              status: curr.status,
              totalPrice: curr.totalPrice,
              orederDetails: [{
                id: curr.id,
                itemInfoId: curr.itemInfoId,
                categoryName: curr.categoryName,
                categoryId: curr.categoryId,
                itemId: curr.itemId,
                itemName: curr.itemName,
                purchasePricePerItem: curr.purchasePricePerItem,
                purchasePrice: curr.purchasePrice,
                salePrice: curr.salePrice,
                salePricePerItem: curr.salePricePerItem,
                qty: curr.qty,
                amount: curr.amount,
                imagePath: environment.productImgPath + "/" + curr.imagePath,
                itemModalName: curr.itemModalName,
                itemModelId: curr.itemModelId,
                whereHouseId: curr.whereHouseId,
                whereHouseName: curr.whereHouseName,
                departmentId: curr.departmentId,
                departmentName: curr.departmentName,
                unitId: curr.unitId,
                sizeId: curr.sizeId,
                subCategoryId: curr.subCategoryId,
                unitName: curr.unitName,
                sizeName: curr.sizeName,
                subCategoryName: curr.subCategoryName
              }]
            });
          }
          return acc;
        }, []);
        this.orderList = groupedData;
      }
    });
  }
}
