<div class="container mb-5 electronics fashion">
  <div class="bg-lighter trending-products">
    <div class="heading heading-flex">
      <div class="heading-left" contentAnim aniName="blurIn">
        <h2 class="title font-weight-bold mb-1">Electric Product</h2>
      </div>

    </div>

    <div class="position-relative">
      <ul ngbNav #nav="ngbNav" class="tab-content tab-content-carousel" #tab [destroyOnHide]="false">
        <li ngbNavItem="best">
          <ng-template ngbNavContent>
            <owl-carousel [options]="sliderOption"
              carouselClasses="electronic-carousel owl-simple carousel-equal-height" *ngIf="!loaded else elseBlock">
              <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
            </owl-carousel>
            <ng-template #elseBlock>
              <owl-carousel [options]="sliderOption"
                carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                <molla-product-twelve [product]="product"
                  *ngFor="let product of products | slice:0 : 15"></molla-product-twelve>
                <div class="product d-flex flex-column overflow-hidden" routerLink="/shop/sidebar/4cols"
                  [queryParams]="{shop: 6}" *ngIf="products.length == 15">
                  <figure class="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                    <h1 class="mx-4 px-4  mt-4 text-center"> More <span class="mx-4 px-4  mt-4"><i
                          class="icon-arrow-right mx-4 px-4 mt-4"></i></span> </h1>
                  </figure>
                </div>
              </owl-carousel>
            </ng-template>
          </ng-template>
        </li>

      </ul>
      <div [ngbNavOutlet]="nav"></div>
      <ul ngbNav #nav1="ngbNav" class="tab-content tab-content-carousel" #tab [destroyOnHide]="false">
        <li ngbNavItem="best">
            <ng-template ngbNavContent>
                <owl-carousel [options]="sliderOption"
                    carouselClasses="electronic-carousel owl-simple carousel-equal-height"
                    *ngIf="!loaded else elseBloc">
                    <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                </owl-carousel>
                <ng-template #elseBloc>
                    <owl-carousel [options]="sliderOption"
                        carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                        <molla-product-twelve [product]="product"
                            *ngFor="let product of otherproducts | slice:0 : 15"></molla-product-twelve>
                        <div class="product d-flex flex-column overflow-hidden" routerLink="/shop/sidebar/4cols"
                            [queryParams]="{shop: 0}" *ngIf="products.length == 15">
                            <figure
                                class="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                                <h1 class="mx-4 px-4  mt-4 text-center"> More <span class="mx-4 px-4  mt-4"><i
                                            class="icon-arrow-right mx-4 px-4 mt-4"></i></span> </h1>
                            </figure>
                        </div>
                    </owl-carousel>
                </ng-template>
            </ng-template>
        </li>

    </ul>
      <div [ngbNavOutlet]="nav1" *ngIf="otherproducts.length > 0"> </div>
      <div class="bottom-end-btn">
        <button class="btn-product text-uppercase text-dark text-decoration-none"
        routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 6}" >More</button>
    </div>

    </div>
  </div>
</div>
