<div class="row">
  <div class="col-md-4"></div>
  <div class="col-md-4 mt-4">
    <form [formGroup]="resetForm" (submit)="login(false)" novalidate>
      <fieldset class="form-group position-relative has-icon-left">
        <label for="singin-password">Password *</label>
        <input type="password" class="form-control" formControlName="password"
            placeholder="Enter Password"
            [ngClass]="{ 'is-invalid': submitted && fL['password'].errors }" />
        <div *ngIf="submitted && fL['password'].errors" class="invalid-feedback">
            <div *ngIf="fL['password'].errors['required']">
                This field is required</div>
        </div>
    </fieldset>
      <fieldset class="form-group position-relative has-icon-left">
          <label for="singin-confirmPassword">confirm Password *</label>
          <input type="password" class="form-control" formControlName="confirmPassword" placeholder="Enter confirmPassword"
              [ngClass]="{ 'is-invalid': submitted && fL['confirmPassword'].errors }" />
          <div *ngIf="submitted && fL['confirmPassword'].errors" class="invalid-feedback">
              <div *ngIf="fL['confirmPassword'].errors['required']">
                  This field is required</div>
          </div>
      </fieldset>
      <div class="form-footer">
          <button type="submit" class="btn btn-outline-primary-2">
              <span>Submit</span>
              <i class="icon-long-arrow-right"></i>
          </button>
      </div>
    </form>

  </div>
</div>
