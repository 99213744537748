import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
	selector: 'molla-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  userCheck: any;
	@Input() containerClass = "container";
	@Input() isBottomSticky = false;

	year: any;

	constructor(private modal:ModalService,private auth: AuthenticationService) {
    this.auth.currentUser.subscribe(res => {
      this.userCheck = res
    })
	}

	ngOnInit(): void {
		this.year = (new Date()).getFullYear();
	}
  showLoginModal(){
    this.modal.showLoginModal();
  }
  logout() {
    localStorage.clear();
    this.auth.setUser();
  }
}
