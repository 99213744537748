<!-- <p>{{orderList | json}}</p> -->

<div class="row">
  <div class="col-md-12">
    <div class=" molla-table-body">
      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact</th>
              <th>Delivery Name</th>
              <th>Address</th>
              <th>Country</th>
              <th>Total Price</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let order of orderList">
              <tr class="parent-row">

                <td>{{order.firstName}}</td>
                <td>{{order.phoneNo}}</td>
                <td>{{order.deliveryName}}</td>
                <td>{{order.addressLine1}}</td>
                <td>{{order.county}}</td>
                <td>{{order.totalPrice}}</td>
                <td>{{order.status ? order.status : 'Pending'}}</td>
                <td>
                  <a (click)="toggle(order.id)">
                    <button class="btn btn-dark">
                      <i class="icon-plus"></i>
                    </button>
                  </a>
                </td>
              </tr>
              <tr [ngClass]="{ 'd-none': !expanded[order.id] }">
                <td colspan="8">
                  <div class="table-responsive">
                    <table class="table table-hover child-table">
                      <thead>
                        <tr>
                          <th>Category Name</th>
                          <th>Product Name</th>
                          <th>Modal Name</th>
                          <!-- <th>Purchase Price</th> -->
                          <th>Qty</th>
                          <th>Amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let detail of order.orederDetails">
                          <td>{{ detail.categoryName }}</td>
                          <td>{{ detail.itemName }}</td>
                          <td>{{ detail.itemModalName }}</td>
                          <!-- <td>{{ detail.purchasePrice }}</td> -->
                          <td>{{ detail.qty }}</td>
                          <td>{{ detail.amount }}</td>
                          <td><img [src]="detail.imagePath" style="max-width: 50%;"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
