
<!-- <ngx-ui-loader [fgsPosition]="config.fgsPosition" [fgsSize]="config.fgsSize"
[bgsColor]="config.bgsColor" [fgsColor]="config.fgsColor" [pbColor]="config.pbColor"
    [fgsType]="config.fgsType"[bgsOpacity]="config.bgsOpacity"
    [bgsPosition]="config.bgsPosition" [bgsSize]="config.bgsSize" [bgsType]="config.bgsType" [gap]="config.gap"
    [logoPosition]="config.logoPosition" [logoSize]="config.logoSize" [logoUrl]="config.logoUrl"
    [overlayColor]="config.overlayColor" [pbDirection]="config.pbDirection"
    [pbThickness]="config.pbThickness" [hasProgressBar]="config.hasProgressBar" [text]="config.text"
    [textColor]="config.textColor" [textPosition]="config.textPosition"></ngx-ui-loader>
 -->
 <ngx-spinner
 bdColor="rgba(51,51,51,0.8)"
 size="medium"
 color="#fff"
 type="ball-scale-multiple"
>
 <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<router-outlet></router-outlet>

<div class="mobile-menu-overlay" (click)="hideMobileMenu()"></div>

<molla-mobile-menu></molla-mobile-menu>
<a target="_blank" href="https://api.whatsapp.com/send?phone=+923051005407&text=''" class="whatsapp-button">
  <i class="fab fa-whatsapp"></i>
</a>
<button id="scroll-top" title="Back to Top" [ngClass]="{show: utilsService.isSticky}"
    (click)="utilsService.scrollTop($event)"><i class="icon-arrow-up"></i></button>
