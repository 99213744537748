import { sliderOpt } from 'src/app/shared/data';

export const introSlider = {
    ...sliderOpt,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    // autoplayHoverPause: true,
    autoplaySpeed: 1000,
    loop: true,
}

export const brandSlider = {
    ...sliderOpt,
    nav: false,
    dots: false,
    margin: 0,
    loop: false,
    responsive: {
        0: {
            items: 2
        },
        480: {
            items: 3
        },
        576: {
            items: 4
        },
        992: {
            items: 5
        },
        1600: {
            items: 6
        }
    }
}

export const productSlider = {
    ...sliderOpt,
    nav: true,
    dots: false,
    margin: 1,
    loop: false,
    items: 2,
    autoHeight: false,
    responsive: {
        480: {
            items: 2
        },
        576: {
            items: 3
        },
        768: {
            items: 4
        },
        992: {
            items: 5
        },
        1200: {
            items: 5
        }
    }
}

export const tabSlider = {
    ...sliderOpt,
    nav: true,
    dots: false,
    margin: 1,
    loop: false,
    items: 2,
    autoHeight: false,
    responsive: {
        480: {
            items: 2
        },
        576: {
            items: 3
        },
        768: {
            items: 4
        },
        992: {
            items: 5
        },
        1200: {
            items: 6
        }
    }
}

export const categorySlider = {
    ...sliderOpt,
    nav: false,
    dots: false,
    items: 2,
    margin: 10,
    loop: false,
    responsive: {
        480: {
            items: 3
        },
        576: {
            items: 4
        },
        768: {
            items: 5
        },
        992: {
            items: 6
        },
        1200: {
            items: 8
        }
    }
}

export const infoSlider = {
    ...sliderOpt,
    nav: false,
    dots: false,
    items: 1,
    margin: 20,
    loop: false,
    responsive: {
        480: {
            items: 1
        },
        576: {
            items: 2
        },
        992: {
            items: 3,
            margin: 0
        },
        1400: {
            items: 4
        }
    }
}

export const instagramSlider = {
    ...sliderOpt,
    nav: false,
    dots: false,
    items: 2,
    margin: 20,
    loop: false,
    responsive: {
        576: {
            items: 3
        },
        992: {
            items: 4
        },
        1200: {
            items: 5
        }
    }
}

export const blogSlider = {
    ...sliderOpt,
    nav: false,
    dots: true,
    items: 3,
    margin: 20,
    loop: false,
    autoHeight: true,
    autoplay: false,
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 2
        },
        992: {
            items: 3
        },
        1200: {
            items: 4
        }
    }
}