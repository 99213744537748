import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { APIResponse } from '../models/a-p-i-Response';
import { CategoryDto } from '../models/category';
import { productInfo } from '../models/dtos/productInfo';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private http: HttpClient, private _authenticationService: AuthenticationService) {
  }

  /**
   * Get Products
   */
  public fetchShopData(params: any, perPage: number, initial = 'shop'): Observable<any> {
    let temp = initial;
    if (!initial.includes('?')) {
      temp += '?';
    }

    for (let key in params) {
      temp += key + '=' + params[key] + '&';
    }

    if (!params.page) {
      temp += 'page=1';
    }

    if (!params.perPage) {
      temp += '&perPage=' + perPage;
    }

    temp += '&demo=' + environment.demo;

    return this.http.get(`${environment.SERVER_URL}/${temp}`);
  }

  /**
   * Get Products
   */
  public fetchBlogData(params: any, initial = 'blogs/classic', perPage: number,): Observable<any> {
    let temp = initial;
    if (!initial.includes('?')) {
      temp += '?';
    }

    for (let key in params) {
      temp += key + '=' + params[key] + '&';
    }

    if (!params.page) {
      temp += 'page=1';
    }

    if (!params.perPage) {
      temp += '&perPage=' + perPage;
    }

    return this.http.get(`${environment.SERVER_URL}/${temp}`);
  }

  /**
   * Get Products
   */
  public fetchSinglePost(slug: string): Observable<any> {
    return this.http.get(`${environment.SERVER_URL}/${'single/' + slug + '?demo=' + environment.demo}`);
  }

  /**
   * Get Products for home page
   */
  public fetchHomeData(): Observable<any> {
    return this.http.get(`${environment.SERVER_URL}/${environment.demo}`);
  }
  get headers() {
    return {
      'Content-Type': 'application/json',
      'Accept': 'q=0.8;application/json;q=0.9',
      // Authorization: `Bearer ${this._authenticationService.currentUserValue.authToken}`,
    };
  }

  getAllCategory(): Observable<APIResponse<CategoryDto[]>> {
    return this.http.get<APIResponse<CategoryDto[]>>(`${environment.SERVER_URL}/Category/GetCategory`, {
      headers: this.headers
    });
  }
  getAddress(userId): Observable<APIResponse<any[]>> {
    return this.http.get<APIResponse<any[]>>(`${environment.SERVER_URL}/api/Account/GetUserAddress?userId=${userId}`, {
      headers: this.headers
    });
  }

  /**
   * Get products by demo
   */
  public getSingleProduct(slug: string, isQuickView = false): Observable<any> {
    return this.http.get(`${environment.SERVER_URL}/products/${slug}?demo=${environment.demo}&isQuickView=${isQuickView}`);
  }
  // Single Product List
  getSingleProductId(id: number, isQuickView = false): Observable<APIResponse<productInfo>> {

    return this.http.get<APIResponse<productInfo>>(`${environment.SERVER_URL}/api/Product/GetProductInfoWithId?ProductId=${id}`, {
      headers: this.headers
    });
  }
  /**
   * Get Products
   */
  public fetchHeaderSearchData(searchTerm: string, cat = null): Observable<any> {
    return this.http.get(`${environment.SERVER_URL}/shop?perPage=5&searchTerm=${searchTerm}&category=${cat}&demo=${environment.demo}`);
  }

  /**
   * Get Element Products
   */
  public fetchElementData(): Observable<any> {
    return this.http.get(`${environment.SERVER_URL}/elements/products`);
  }

  /**
   * Get Element Blog
   */
  public fetchElementBlog(): Observable<any> {
    return this.http.get(`${environment.SERVER_URL}/elements/blogs`);
  }
  saveAddress(form:any): Observable<APIResponse<any>> {
		var getResponse = this.http.post<APIResponse<any>>(`${environment.SERVER_URL}/api/Account/SaveUserAddress`, form, {
			headers: this.headers
		});
		return getResponse;
	}
}
