import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlModule } from 'angular-owl-carousel';

import { SharedModule } from '../../shared/shared.module';

import { IndexComponent } from './index/index.component';
import { TrendyCollectionComponent } from './trendy-collection/trendy-collection.component';
import { NewCollectionComponent } from './new-collection/new-collection.component';
import { ElectronicsCollectionComponent } from './electronics-collection/electronics-collection.component';
import { FashionCollectionComponent } from './fashion-collection/fashion-collection.component';
import { BlogCollectionComponent } from './blog-collection/blog-collection.component';
import { TopCategoryComponent } from './top-category/top-category.component';
import { TopProductComponent } from './top-product/top-product.component';
import { ElectricCollentionComponent } from './electric-collention/electric-collention.component';
import { FebricCollectionComponent } from './febric-collection/febric-collection.component';
import {  FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeneralShopComponent } from './general-shop/general-shop.component';

@NgModule({
	declarations: [
		IndexComponent,
		TrendyCollectionComponent,
		NewCollectionComponent,
		ElectronicsCollectionComponent,
		FashionCollectionComponent,
		BlogCollectionComponent,
  TopCategoryComponent,
  TopProductComponent,
  ElectricCollentionComponent,
  FebricCollectionComponent,
  GeneralShopComponent,
	],

	imports: [
    FormsModule,
    ReactiveFormsModule,
		CommonModule,
		RouterModule,
		NgbModule,
		OwlModule,
		SharedModule
	]
})

export class HomeModule { }
