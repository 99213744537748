<div class="container product-group mb-lg-7 mb-4">
    <div class="row justify-content-center">
        <div class="arrivals col-lg-4 col-md-6 mb-2 mb-xl-0">
            <div class="heading heading-flex align-items-center">
                <div class="heading-left">
                    <h2 class="title mb-0 font-weight-bold">New Arrivals</h2>
                </div>
                <div class="heading-right mt-0">
                    <a routerLink="/shop/sidebar/4cols" class="title-link font-size-normal text-uppercase font-weight-normal shadow-none">
                        View More<i class="icon-long-arrow-right"></i>
                    </a>
                </div>
            </div>
            <div class="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0" *ngIf="!loaded else loadedBlock1">
                <div class="skel-pro skel-product-col mb-2 ml-4" *ngFor="let item of [1,2,3]"></div>
            </div>
            <ng-template #loadedBlock1>
                <div class="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0">
                    <molla-product-thirteen [product]="product" *ngFor="let product of (products| attrFilter : 'new') | slice :0 :3"></molla-product-thirteen>
                </div>
            </ng-template>
        </div>
        <div class="recommend col-lg-4 mb-2 mb-xl-0 order-lg-0 order-md-first">
            <div class="heading heading-flex align-items-center">
                <div class="heading-left">
                    <h2 class="title mb-0 font-weight-bold">Recommended Products</h2>

                </div>
                <div class="heading-right mt-0">
                    <a routerLink="/shop/sidebar/4cols" class="title-link font-size-normal text-uppercase font-weight-normal shadow-none">
                        View More<i class="icon-long-arrow-right"></i>
                    </a>
                </div>

            </div>
            <div class="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0" *ngIf="!loaded else loadedBlock2">
                <div class="skel-pro skel-product-col mb-2 ml-4" *ngFor="let item of [1,2,3]"></div>
            </div>
            <ng-template #loadedBlock2>
                <div class="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0">
                    <molla-product-thirteen [product]="product" *ngFor="let product of (products| attrFilter : 'featured') | slice : 0: 3"></molla-product-thirteen>
                </div>
            </ng-template>
        </div>
        <div class="expert col-lg-4 col-md-6 mb-2 mb-xl-0">
            <div class="heading heading-flex align-items-center">
                <div class="heading-left">
                    <h2 class="title mb-0 font-weight-bold">Expert Picks</h2>
                </div>

                <div class="heading-right mt-0">
                    <a routerLink="/shop/sidebar/4cols" class="title-link font-size-normal text-uppercase font-weight-normal shadow-none">
                        View More<i class="icon-long-arrow-right"></i>
                    </a>
                </div>

            </div>
            <div class="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0" *ngIf="!loaded else loadedBlock3">
                <div class="skel-pro skel-product-col mb-2 ml-4" *ngFor="let item of [1,2,3]"></div>
            </div>
            <ng-template #loadedBlock3>
                <div class="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0">
                    <molla-product-thirteen [product]="product" *ngFor="let product of (products| attrFilter : 'top')| slice: 0 : 3"></molla-product-thirteen>
                </div>
            </ng-template>
        </div>
    </div>
</div>
