import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Product } from 'src/app/shared/classes/product';

import { ModalService } from 'src/app/shared/services/modal.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { WishlistService } from 'src/app/shared/services/wishlist.service';
import { CompareService } from 'src/app/shared/services/compare.service';

import { productInfo } from 'src/app/shared/models/dtos/productInfo';
import { environment } from 'src/environments/environment.prod';

@Component({
    selector: 'molla-product-twelve',
    templateUrl: './product-twelve.component.html',
    styleUrls: ['./product-twelve.component.scss']
})

export class ProductTwelveComponent implements OnInit {

    @Input() product: productInfo;
    @Input() addClass = '';
    @Input() isShownSold = false;

    maxPrice = 0;
    minPrice = 99999;
	currencyText = environment.currencyText;

    SERVER_URL = environment.serverApiUrl;
	productImgPath=environment.productImgPath;
    constructor(
        private router: Router,
        private modalService: ModalService,
        private cartService: CartService,
        private wishlistService: WishlistService,
        private compareService: CompareService
    ) { }

    ngOnInit(): void {
        // let min = this.minPrice;
        // let max = this.maxPrice;

        // this.product.variants.map(item => {
        //     if (min > item.price) min = item.price;
        //     if (max < item.price) max = item.price;
        // }, []);

        // if (this.product.variants.length == 0) {
        //     min = this.product.sale_price
        //         ? this.product.sale_price
        //         : this.product.price;
        //     max = this.product.price;
        // }

        // this.minPrice = min;
        // this.maxPrice = max;
    }

    addToCart(event: Event) {
        event.preventDefault();
        this.cartService.addToCart(this.product);
    }

    addToWishlist(event: Event) {
        event.preventDefault();

        if (this.isInWishlist()) {
            this.router.navigate(['/shop/wishlist']);
        } else {
            this.wishlistService.addToWishList(this.product);
        }
    }

    addToCompare(event: Event) {
        event.preventDefault();
        if (this.isInCompare()) return;
        this.compareService.addToCompare(this.product);
    }

    quickView(event: Event,product:any) {
        console.log("click on Quick View " + product)
        event.preventDefault();
        this.modalService.showQuickViewS(product);
    }

    isInCompare() {
        return this.compareService.isInCompare(this.product);
    }

    isInWishlist() {
        return this.wishlistService.isInWishlist(this.product);
    }
    disabaledCheckInType(id){
     
        // [
     //     {
     //       "Description": "Time In",
     //       "LookupValue": 56
     //     },
     //     {
     //       "Description": "Time Out",
     //       "LookupValue": 57
     //     },
     //     {
     //       "Description": "Break In",
     //       "LookupValue": 58
     //     },
     //     {
     //       "Description": "Break out",
     //       "LookupValue": 59
     //     }
     //   ]
     
    //  this.checkinTypes =this.checkinTypes;
    //  const p1 = this.checkinTypes.find((p) => p.lookupValue == 56);
    //  const p2 = this.checkinTypes.find((p) => p.lookupValue == 57);
    //  const p3 = this.checkinTypes.find((p) => p.lookupValue == 58);
    //  const p4 = this.checkinTypes.find((p) => p.lookupValue == 59);
    //  p1.disabled = false;
    //  p2.disabled = false;
    //  p3.disabled = false;
    //  p4.disabled = false;

    //  if(id == 56){
    //      p2.disabled = true;
    //      p3.disabled = true;
    //   }
    //   else if(id == 58){
    //     p1.disabled = true;
    //     p3.disabled = true;
    //     p2.disabled = true;
    //   }
    //   else if(id == 59){
    //     p2.disabled = true;
    //      p3.disabled = true;
    //   }
    //   else if(id == 57){
    //      p1.disabled = false;
    //      p2.disabled = true;
    //      p3.disabled = true;
    //      p4.disabled = true;
    //   }

 }
}