<div class="soon">
    <div class="container">
        <div class="row">
            <div class="col-md-9 col-lg-8">
                <div class="soon-content text-center">
                    <div class="soon-content-wrapper">
                        <img src="assets/images/logo-icon.png" alt="Logo" class="soon-logo mx-auto">
                        <h1 class="soon-title">Coming Soon</h1>
                        <molla-count-down until="2023-09-08" format="DHMS" wrap="coming-countdown countdown-separator">
                        </molla-count-down>

                        <hr class="mt-2 mb-3 mt-md-3">
                        <p>We are currently working on an awesome new site. Stay tuned for more information.
                            Subscribe to our newsletter to stay updated on our progress.</p>
                        <form action="#">
                            <div class="input-group mb-5">
                                <input type="email" class="form-control bg-transparent"
                                    placeholder="Enter your Email Address" required>
                                <div class="input-group-append">
                                    <button class="btn btn-outline-primary-2" type="submit">
                                        <span>SUBSCRIBE</span>
                                        <i class="icon-long-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div class="social-icons justify-content-center mb-0">
                            <a class="social-icon" href="javascript:;">
                                <i class="icon-facebook-f"></i>
                            </a>
                            <a class="social-icon" href="javascript:;"><i class="icon-twitter"></i>
                            </a>
                            <a class="social-icon" href="javascript:;"><i class="icon-instagram"></i>
                            </a>
                            <a class="social-icon" href="javascript:;"><i class="icon-youtube"></i>
                            </a>
                            <a class="social-icon" href="javascript:;"><i class="icon-pinterest"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="soon-bg bg-image" style="background-image: url(assets/images/backgrounds/soon-bg.jpg)"></div>

</div>