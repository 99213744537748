<div class="mobile-menu-container mobile-menu-light">
    <div class="mobile-menu-wrapper">
        <span class="mobile-menu-close" (click)="hideMobileMenu()"><i class="icon-close"></i></span>

        <form action="#" method="get" class="mobile-search" (submit)="submitSearchForm($event)">
            <label for="mobile-search" class="sr-only">Search</label>
            <input type="search" class="form-control" name="mobile-search" id="mobile-search" placeholder="Search in..."
                required>
            <button class="btn btn-primary" type="submit"><i class="icon-search"></i></button>
        </form>

        <ul ngbNav #nav="ngbNav" class="nav nav-pills-mobile nav-border-anim">
            <li ngbNavItem>
                <a ngbNavLink>
                    Menu
                </a>
                <ng-template ngbNavContent>
                    <nav class="mobile-cats-nav">
                        <ul class="mobile-cats-menu">
                            <li class="item-cats-lead"><a routerLink="/">Home</a></li>
                            <li class="item-cats-lead"><a routerLink="/shop/sidebar/4cols">Shop</a></li>
                            <li class="item-cats-lead"><a routerLink="/shop/sidebar/4cols">Rang o Riwayat</a></li>
                            <li class="item-cats-lead"><a routerLink="/pages/about">About Us</a></li>
                            <li class="item-cats-lead"><a routerLink="/pages/contact">Contact Us</a></li>
                        </ul>
                    </nav>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>
                    Categories
                </a>
                <ng-template ngbNavContent>
                    <nav class="mobile-cats-nav">
                        <ul class="mobile-cats-menu">
                            <li class="item-cats-lead"><a routerLink="/shop/sidebar/3cols"
                                    [queryParams]="{category: 'electronics'}">Electronics</a></li>
                            <li class="item-cats-lead"><a routerLink="/shop/sidebar/3cols"
                                    [queryParams]="{category: '1'}">Furniture</a></li>
                            <li><a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'beds'}">Beds</a></li>
                            <li><a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'lighting'}">Lighting</a>
                            </li>
                            <li><a routerLink="/shop/sidebar/3cols"
                                    [queryParams]="{category: '2'}">Cooking</a></li>
                            <li><a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'clothing'}">Clothing</a>
                            </li>
                            <li><a routerLink="/shop/sidebar/3cols"
                                    [queryParams]="{category: 'home-appliances'}">Home Appliances</a></li>
                            <li><a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'beauty'}">Healthy &amp; Beauty
                                </a></li>
                            <li><a routerLink="/shop/sidebar/3cols"
                                    [queryParams]="{category: 'shoes'}">Shoes &amp; Boots</a></li>
                            <li><a routerLink="/shop/sidebar/3cols"
                                    [queryParams]="{category: 'travel'}">Travel &amp; Outdoor</a></li>
                            <li><a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'smart-phone'}">Smart Phones</a></li>
                        </ul>
                    </nav>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>

        <div class="social-icons">
            <a href="javascript:;" class="social-icon" title="Facebook"><i class="icon-facebook-f"></i></a>
            <a href="javascript:;" class="social-icon" title="Twitter"><i class="icon-twitter"></i></a>
            <a href="javascript:;" class="social-icon" title="Instagram"><i class="icon-instagram"></i></a>
            <a href="javascript:;" class="social-icon" title="Youtube"><i class="icon-youtube"></i></a>
        </div>
    </div>
</div>
