import { Component, OnInit } from '@angular/core';

import { ModalService } from 'src/app/shared/services/modal.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

import { introSlider, brandSlider, infoSlider, categorySlider } from '../data';
import { CategoryService } from 'src/app/shared/services/category.service';
import { CategoryDto } from 'src/app/shared/models/category';
import { ProductService } from 'src/app/shared/services/product.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { CartService } from 'src/app/shared/services/cart.service';

@Component({
  selector: 'molla-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {
  currencyText = environment.currencyText;
  productImgPath = environment.productImgPath
  searchProductByName$ = new Subject<any>();
  products = [];
  otherproducts = [];
  posts = [];
  topProducts = [];
  febricProducts = [];
  otherfebricProducts = [];
  generalProducts = [];
  othergeneralProducts = [];
  elecProducts = [];
  otherelecProducts = [];
  electronicProducts = [];
  otherelectronicProducts = [];
  garmentsProducts = [];
  othergarmentsProducts = [];
  categoryList1 = [];
  // subcategoryList = [];
  items = [1, 2, 3, 4];
  loaded = false;
  topProductloaded = false;
  garmentsloaded = false;
  electricloaded = false;
  electronicloaded = false;
  febricloaded = false;
  generalloaded = false;
  catgloaded = false;
  introSlider = introSlider;
  brandSlider = brandSlider;
  infoSlider = infoSlider;
  categorySlider = categorySlider;
  productName: string;
  product: any[] = [];
  searchData = false;

  constructor(public apiService: ApiService,
    public productService: ProductService,
    private cartService: CartService,
    public categoryService: CategoryService, public utilsService: UtilsService, private modalService: ModalService,) {
    this.modalService.openNewsletter();

    // this.categoryService.getSubAllCategory().subscribe(res => {

    //   if (res.successFlag) {
    //     this.subcategoryList = [];
    //     this.subcategoryList.splice(0, this.subcategoryList.length);
    //     this.subcategoryList.push(...res.data);
    //     this.catgloaded = true;
    //   }
    // });
    // this.apiService.fetchHomeData().subscribe(result => {
    //
    // 	this.products = result.products;
    // 	this.posts = result.blogs;
    // 	this.topProducts = utilsService.attrFilter(result.products, 'top');
    // 	this.newProducts = utilsService.attrFilter(result.products, 'new');
    // 	this.elecProducts = utilsService.catFilter(result.products, ['electronics']);
    // 	this.loaded = true;
    // })
    // this.productService.getGetProductInfobyPraram('', 30, 0).subscribe(result => {
    //   if (result.successFlag) {
    //     if (result.data.length > 15) {
    //       let products = result.data.slice(0, 15);
    //       this.products = products;
    //       this.otherproducts = result.data.slice(15, result.data.length);
    //     } else
    //       this.products = result.data;
    //     this.topProductloaded = true;
    //     // this.utilsService.scrollToPageContent();
    //   }
    // }, error => {
    //   this.topProductloaded = true;
    //   console.log(error);
    // })
    this.productService.getGetProductInfobyPraram('', 30, 7).subscribe(result => {
      if (result.successFlag) {
        if (result.data.length > 15) {
          let products = result.data.slice(0, 15);
          this.garmentsProducts = products;
          this.othergarmentsProducts = result.data.slice(15, result.data.length);
        } else
          this.garmentsProducts = result.data;
        this.garmentsloaded = true;
        // this.utilsService.scrollToPageContent();
      }
    }, error => {
      this.garmentsloaded = true;
      console.log(error);
    })
    this.productService.getGetProductInfobyPraram('', 30, 6).subscribe(result => {
      if (result.successFlag) {
        if (result.data.length > 15) {
          let products = result.data.slice(0, 15);
          this.elecProducts = products;
          this.otherelecProducts = result.data.slice(15, result.data.length);
        } else
          this.elecProducts = result.data;
        this.electricloaded = true;
        // this.utilsService.scrollToPageContent();
      }
    }, error => {
      this.electricloaded = true;
      console.log(error);
    })
    this.productService.getGetProductInfobyPraram('', 30, 8).subscribe(result => {
      if (result.successFlag) {
        if (result.data.length > 15) {
          let products = result.data.slice(0, 15);
          this.electronicProducts = products;
          this.otherelectronicProducts = result.data.slice(15, result.data.length);
        } else
          this.electronicProducts = result.data;
        this.electronicloaded = true;
        // this.utilsService.scrollToPageContent();
      }
    }, error => {
      this.electronicloaded = true;
      console.log(error);
    })
    this.productService.getGetProductInfobyPraram('', 30, 10002).subscribe(result => {
      if (result.successFlag) {
        if (result.data.length > 15) {
          let products = result.data.slice(0, 15);
          this.febricProducts = products;
          this.otherfebricProducts = result.data.slice(15, result.data.length);
        } else
          this.febricProducts = result.data;
        this.febricloaded = true;
        // this.utilsService.scrollToPageContent();
      }
    }, error => {
      this.febricloaded = true;
      console.log(error);
    })
    this.productService.getGetProductInfobyPraram('', 30, 20002).subscribe(result => {
      if (result.successFlag) {
        if (result.data.length > 15) {
          let products = result.data.slice(0, 15);
          this.generalProducts = products;
          this.othergeneralProducts = result.data.slice(15, result.data.length);
        } else
          this.generalProducts = result.data;
        this.generalloaded = true;
        // this.utilsService.scrollToPageContent();
      }
    }, error => {
      this.generalloaded = true;
      console.log(error);
    })
  }
  addToCart(event: Event,item) {
    event.preventDefault();
    this.cartService.addToCart(item);
}
  ngOnInit(): void {
    // alert("wlcom") call p[ick kro]
    this.searchProductByName$
    .pipe(debounceTime(500)) // Adjust the debounce time as needed
    .subscribe(value => {
      if(value != ""){
        this.productName = value.toString();
        this.searchProduct();
      }
    });
  }
  close(){
    this.product = [];
    this.productName = "";
    this.searchData = false;
  }
  search(){
    if(this.productName !="")
      this.searchProductByName$.next(this.productName);
    if(this.productName == ""){
      // this.searchProductByName$.next(this.productName);
      this.product = [];
    }
  }
  onPageClick(event: MouseEvent) {
    // const targetElement = event.target as HTMLElement;
    // const searchContainer = document.querySelector('.search-container');

    // // Check if the clicked element is outside the search container
    // if (searchContainer && !searchContainer.contains(targetElement)) {
      // }
        this.searchData = false;
        this.product = [];
  }
  searchProduct() {
    this.productService.getProductByName(this.productName).subscribe(
      (response) => {
        // Assign the fetched product to the 'product' variable
        if(response.successFlag)
        if(response.data.length > 0)
            this.product = response.data;
        else{
          this.product = [];
          this.searchData= true;
        }
      },
      (error) => {
        console.error('Error fetching product:', error);
      }
    );
  }

  // loadAllSubCategory() {

  //   this.categoryService.getSubAllCategory().subscribe(res => {

  //     // if (environment.showLogs) {
  //     // 	console.log(res);
  //     // }
  //     this.subcategoryList = [];
  //     if (res.successFlag) {
  //       this.subcategoryList.splice(0, this.subcategoryList.length);
  //       this.subcategoryList.push(...res.data);
  //     }
  //   }, error => {
  //     // if (environment.logErrors) {
  //     // 	console.log(error);
  //     // }
  //   })
  // }
}
