import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UtilsService } from 'src/app/shared/services/utils.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'molla-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  electric = '';
  electronic = '';
  fabric = '';
  general = '';
  garments = '';
  @Input() containerClass = "container";
  userCheck: any;
  wishCount = 0;
  categoryList = [];
  electricCategory = [];
  ElectronicCategory = [];
  ElectronicCategoryList = [];
  electricCategoryList = [];
  garmentsCategoryList = [];
  garmentsCategory = [];
  fabricCategory = [];
  fabricCategoryList = [];
  generalCategory = [];
  generalCategoryList = [];
  constructor(public activeRoute: ActivatedRoute,
    private router: Router,
    public utilsService: UtilsService, private categoryService: CategoryService, private auth: AuthenticationService,
    public modalService: ModalService) {
    this.categoryService.getAllCategory().subscribe(res => {
      if (res.successFlag) {
        this.categoryList = [];
        this.categoryList.splice(0, this.categoryList.length);
        this.categoryList.push(...res.data);
        this.electricCategoryList = this.categoryList.filter(a => a.companyId == 6);
        this.electricCategory = this.categoryList.filter(a => a.companyId == 6);
        this.ElectronicCategoryList = this.categoryList.filter(a => a.companyId == 8);
        this.ElectronicCategory = this.categoryList.filter(a => a.companyId == 8);
        this.garmentsCategoryList = this.categoryList.filter(a => a.companyId == 7);
        this.garmentsCategory = this.categoryList.filter(a => a.companyId == 7);
        this.fabricCategoryList = this.categoryList.filter(a => a.companyId == 10002);
        this.fabricCategory = this.categoryList.filter(a => a.companyId == 10002);
        this.generalCategoryList = this.categoryList.filter(a => a.companyId == 20002);
        this.generalCategory = this.categoryList.filter(a => a.companyId == 20002);
      }
    });
    this.auth.currentUser.subscribe(res => {
      this.userCheck = res
    })
  }

  ngOnInit(): void {
  }

  showLoginModal(event: Event): void {
    event.preventDefault();
    this.modalService.showLoginModal();
  }
  profile() {
    this.router.navigateByUrl('/shop/dashboard')
  }
  logout() {
    localStorage.clear();
    this.auth.setUser();
    // this.auth.logout().subscribe(res=>{
    // })
    // this.router.navigate(["/"])
    // window.location.reload();
  }
  getName(obj: any) {
    return obj.split('@')[0];
  }
  makeAvatar(obj: any) {
    let nameArray = obj.split('@');
    return nameArray[0].charAt(0)
  }
  changeElectricCategory() {
    if (this.electric)
      this.electricCategoryList = this.electricCategory.filter(a => a.categoryName.toLowerCase().includes(this.electric.toLowerCase()));
    else
      this.electricCategoryList = this.electricCategory;

  }
  assignAllElectric() {
    this.electricCategoryList = this.electricCategory;
  }
  changeGarmentCategory() {
    if (this.garments)
      this.garmentsCategoryList = this.garmentsCategory.filter(a => a.categoryName.toLowerCase().includes(this.garments.toLowerCase()));
    else
      this.garmentsCategoryList = this.garmentsCategory;

  }
  assignAllGarment() {
    this.garmentsCategoryList = this.garmentsCategory;
  }
  changeElectronicCategory() {
    if (this.electronic)
      this.ElectronicCategoryList = this.ElectronicCategory.filter(a => a.categoryName.toLowerCase().includes(this.electronic.toLowerCase()));
    else
      this.ElectronicCategoryList = this.ElectronicCategory;

  }
  changeFabricCategory() {
    if (this.fabric)
      this.fabricCategoryList = this.fabricCategory.filter(a => a.categoryName.toLowerCase().includes(this.fabric.toLowerCase()));
    else
      this.fabricCategoryList = this.fabricCategory;

  }
  changeGeneralCategory() {
    if (this.general)
      this.generalCategoryList = this.generalCategory.filter(a => a.categoryName.toLowerCase().includes(this.general.toLowerCase()));
    else
      this.generalCategoryList = this.generalCategory;

  }
  assignAllElectronic() {
    this.ElectronicCategoryList = this.ElectronicCategory;
  }
  assignAllfabric() {
    this.fabricCategoryList = this.fabricCategory;
  }
  assignAllgeneral() {
    this.generalCategoryList = this.generalCategory;
  }
}
