<div class="dropdown cart-dropdown">
    <a href="javascript:;" class="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false" data-display="static">
        <div class="icon">
            <i class="icon-shopping-cart"></i>
            <span class="cart-count">{{ cartService.qtyTotal | async }}</span>
        </div>
        <p>Cart</p>
    </a>

    <div class="dropdown-menu dropdown-menu-right"
        [ngClass]="{'text-center': (cartService.cartStream | async).length == 0}">
        <p *ngIf="(cartService.cartStream | async).length == 0 else elseBlock">No products in the cart.</p>

        <ng-template #elseBlock>
            <div class="dropdown-cart-products">
                <div class="product justify-content-between" *ngFor="let product of cartService.cartStream | async">
                    <div class="product-cart-details">
                        <h4 class="product-title">
                            <a [routerLink]="['/product/default/'+ product.id]">{{ product.itemName }}</a>
                        </h4>

                        <span class="cart-product-info">
                            <span class="cart-product-qty">{{ product.qty }}</span>
                            x {{currencyText}}{{ product.salePricePerItem ? product.salePricePerItem: product.salePricePerItem }}
                        </span>
                    </div>
                    <!-- error -->
                    <figure class="product-image-container">
                        <a [routerLink]="['/product/default/'+ product.id]" class="product-image">
                            <img [src]="SERVER_URL +'/' + product.imagePath" alt="product" width="50" height="50">
                        </a>
                    </figure>

                    <figure class="product-image-container">
                        <a [routerLink]="['/product/default/'+ product.id]" class="product-image">
                        </a>
                    </figure>

                    <a href="javascript:;" class="btn-remove" title="Remove Product"
                        (click)="removeFromCart( $event, product )"><i class="icon-close"></i></a>
                </div>
            </div>

            <div class="dropdown-cart-total">
                <span>Total</span>

                <span class="cart-total-price">{{currencyText}}{{ (cartService.priceTotal | async) }}</span>
            </div>

            <div class="dropdown-cart-action">
                <a routerLink="/shop/cart" class="btn btn-primary">View Cart</a>
                <a (click)="check()" class="btn btn-outline-primary-2"><span>Checkout</span><i
                        class="icon-long-arrow-right"></i></a>
            </div>
        </ng-template>
    </div>
</div>
