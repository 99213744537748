import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../services/category.service';

@Component({
  selector: 'molla-category-menus',
  templateUrl: './category-menus.component.html',
  styleUrls: ['./category-menus.component.scss']
})
export class CategoryMenusComponent implements OnInit {


  categoryList = [];

  constructor(public categoryService: CategoryService) {
    this.categoryService.getToCategory().subscribe(res => {

      if (res.successFlag) {
        this.categoryList = [];
        this.categoryList.splice(0, this.categoryList.length);
        this.categoryList.push(...res.data);
      }
    });
  }

  ngOnInit(): void {
  }


  loadAllCategory() {

    this.categoryService.getAllCategory().subscribe(res => {

      // if (environment.showLogs) {
      // 	console.log(res);
      // }
      this.categoryList = [];
      if (res.successFlag) {
        this.categoryList.splice(0, this.categoryList.length);
        this.categoryList.push(...res.data);
      }
    }, error => {
      // if (environment.logErrors) {
      // 	console.log(error);
      // }
    })
  }

}
