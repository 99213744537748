import { Component, Input, OnInit } from '@angular/core';
import { tabSlider } from '../data';

@Component({
  selector: 'molla-general-shop',
  templateUrl: './general-shop.component.html',
  styleUrls: ['./general-shop.component.scss']
})
export class GeneralShopComponent implements OnInit {
  @Input() otherproducts = [];
  @Input() products = [];
	@Input() loaded = false;
  sliderOption = tabSlider;
  constructor() { }

  ngOnInit(): void {
  }

}
