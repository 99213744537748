<div class="container banner-group-1" *ngIf="catgloaded">
    <div class="categories pb-5">
        <h3 class="title text-center font-weight-bold mt-4" contentAnim aniName="blurIn">Top Products</h3>
        <owl-carousel carouselClasses="carousel-theme carousel-simple carousel-with-shadow row cols-2 cols-xs-3 cols-sm-4 cols-md-5 cols-lg-6 cols-xl-8" [options]="categorySlider">


            <!-- <div *ngFor="let item of categoryList; let i=index" class="category1 position-relative">
                <div class="category-image">
                    <a routerLink="/shop/sidebar/4cols" [queryParams]="{category: 'lighting'}">
                        <img src="assets/images/home/categories/3.png" class="w-100" alt="" width="166" height="160">
                    </a>
                </div>
                <div class="category-body letter-spacing-normal font-size-normal text-center position-absolute text-uppercase">
                    <a routerLink="/shop/sidebar/4cols" [queryParams]="{category: 'lighting'}" class="category-title text-truncate font-weight-normal">Dates</a>
                </div>
            </div> -->

            <div *ngFor="let item of topSubCategory" class="category1 position-relative">
                <div class="category-image">
                    <a routerLink="/shop/sidebar/4cols" [queryParams]="{category: item.id}">
                        <img src="{{SERVER_URL}}/{{subCategoryImgPath}}/{{item.imagePath}}" class="w-100" alt="" width="166" height="160">
                    </a>
                </div>
                <div class="category-body letter-spacing-normal font-size-normal text-center position-absolute text-uppercase">
                    <a routerLink="/shop/sidebar/4cols" [queryParams]="{category: item.categoeyId}" class="category-title text-truncate font-weight-normal">{{item.subCategoryName}}</a>
                </div>
            </div>

        </owl-carousel>
    </div>
    <!-- <div class="row">
        <div class="col-md-4 mb-md-0 mb-2" contentAnim aniName="fadeInLeftShorter">
            <div class="banner banner-overlay bg-image h-100 d-flex justify-content-end mb-0" style="background-image: url(assets/images/home/banners/banner-2.jpg);">
                <div class="banner-content position-relative d-flex flex-column justify-content-center">
                    <h4 class="banner-subtitle letter-spacing-normal font-size-normal text-white d-none d-sm-block font-weight-light">
                        <a routerLink="/shop/sidebar/3cols">Headphone Savings</a>
                    </h4>

                    <h3 class="banner-title text-white mb-0 font-weight-bold">
                        <a routerLink="/shop/sidebar/3cols">Headphone
                            <br>Trending
                            <br>JBL Harman</a>
                    </h3>

                    <a routerLink="/shop/sidebar/3cols" class="btn font-size-normal btn-primary text-uppercase text-dark btn-rounded text-center">
                        <span>Shop Now </span>
                        <i class="icon-long-arrow-right d-inline-block"></i>
                    </a>
                </div>

            </div>
        </div>
        <div class="col-md-4 mb-md-0 mb-2">
            <div class="banner banner-overlay bg-image h-100 d-flex justify-content-end mb-0" style="background-image: url(assets/images/home/banners/banner-3.jpg);">
                <div class="banner-content position-relative d-flex flex-column justify-content-center">
                    <h4 class="banner-subtitle letter-spacing-normal font-size-normal d-none d-sm-block font-weight-light">
                        <a routerLink="/shop/sidebar/3cols">Weekend Sale</a>
                    </h4>

                    <h3 class="banner-title mb-0 font-weight-bold">
                        <a routerLink="/shop/sidebar/3cols">Home Furnishings
                            <br>Outdoor &amp; Office</a>
                    </h3>

                    <a routerLink="/shop/sidebar/3cols" class="btn font-size-normal btn-primary text-uppercase text-dark btn-rounded text-center d-inline-block">
                        <span>Shop Now </span>
                        <i class="icon-long-arrow-right d-inline-block"></i>
                    </a>
                </div>

            </div>
        </div>
        <div class="col-md-4 mb-md-0 mb-2" contentAnim aniName="fadeInRightShorter">
            <div class="banner banner-overlay bg-image h-100 d-flex justify-content-end mb-0" style="background-image: url(assets/images/home/banners/banner-4.jpg);">
                <div class="banner-content position-relative d-flex flex-column justify-content-center">
                    <h4 class="banner-subtitle letter-spacing-normal font-size-normal text-white font-weight-light d-none d-sm-block">
                        <a routerLink="/shop/sidebar/3cols">Amazing Value</a>
                    </h4>

                    <h3 class="banner-title text-white mb-0 font-weight-bold">
                        <a routerLink="/shop/sidebar/3cols">Clothes Trending
                            <br>Spring / Summer </a>
                    </h3>

                    <h4 class="banner-text font-weight-normal text-secondary mb-0">from $12.99</h4>
                    <a routerLink="/shop/sidebar/3cols" class="btn font-size-normal btn-primary text-uppercase text-dark btn-rounded text-center">
                        <span>Discover Now </span>
                        <i class="icon-long-arrow-right d-inline-block"></i>
                    </a>
                </div>

            </div>
        </div>
    </div> -->
</div>
