import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject, BehaviorSubject } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { Product } from 'src/app/shared/classes/product';
import { CartItem } from 'src/app/shared/classes/cart-item';
import { cartItemsSelector } from 'src/app/core/selectors/selectors';
import { AddToCartAction, RemoveFromCartAction, UpdateCartAction } from 'src/app/core/actions/actions';
import { productInfo } from '../models/dtos/productInfo';
import { APIResponse } from '../models/a-p-i-Response';
import { Order } from '../models/dtos/Orders';
import { OrderDetail } from '../models/dtos/OrderDetail';
import { AuthenticationService } from './authentication.service';

@Injectable({
	providedIn: 'root'
})

export class CartService {

	public cartItems: CartItem[] = [];
	public cartStream: Subject<any> = new BehaviorSubject([]);
	public qtyTotal: Subject<number> = new BehaviorSubject(0);
	public priceTotal: Subject<number> = new BehaviorSubject(0);
	public subqtyTotal: Subject<number> = new BehaviorSubject(0);
	public subpriceTotal: Subject<number> = new BehaviorSubject(0);
	constructor(private store: Store<any>, private toastrService: ToastrService, private http: HttpClient, private _authenticationService: AuthenticationService) {
		store.pipe(select(cartItemsSelector)).subscribe(items => {

			this.cartItems = items;

			this.cartStream.next(items);

			this.qtyTotal.next(
				this.cartItems.reduce((acc, cur) => {
					return acc + cur.qty
				}, 0));

			this.priceTotal.next(

				this.cartItems.reduce((acc, cur) => {

					return cur.salePrice + acc
				}, 0)
			)
		})
	}

	// Product Add to Cart
	addToCart(product: productInfo, qty = 1) {

		if (this.canAddToCart(product, qty)) {
			this.store.dispatch(new AddToCartAction({ product, qty }));
			this.toastrService.success('Product added to Cart.');
		} else {
			this.toastrService.error('Sorry, you can\'t add that amount to the cart.');
		}
	}

	// Product Removed from the Cart
	removeFromCart(product: CartItem,isShow?:boolean) {
		this.store.dispatch(new RemoveFromCartAction({ product }));
    if(!isShow)
		  this.toastrService.success('Product removed from Cart.');
	}

	// Cart update
	updateCart(cartItems: CartItem[]) {
		this.store.dispatch(new UpdateCartAction({ cartItems }));
		this.toastrService.success('Cart Updated.');
	}

	// Check whether product is in Cart or not
	isInCart(product: productInfo): boolean {
		return this.cartItems.find(item => item.id == product.id) ? true : false;
	}

	// Check where product could be added to the cart
	canAddToCart(product: productInfo, qty = 1) {
		var find = this.cartItems.find(item => item.id == product.id);

		if (find) {
			// find.salePrice = find.salePricePerItem;
			if (product.stock == 0 || (product.stock && product.stock < (find.qty + qty))) return false;
			else return true;
		} else {
			product.salePrice = product.salePricePerItem;
			if (product.stock == 0 || (product.stock && product.stock < qty)) return false;
			else return true;
		}
	}

	get headers() {
		return {
			'Content-Type': 'application/json',
			'Accept': 'q=0.8;application/json;q=0.9',
			// Authorization: `Bearer ${this._authenticationService.currentUserValue.authToken}`,
		};
	}

	saveOrderDetail(order: Order): Observable<APIResponse<any>> {

		var getResponse = this.http.post<APIResponse<any>>(`${environment.SERVER_URL}/api/Sale/SaveOrder`, order, {
			headers: this.headers
		});
		return getResponse;
	}
	// degressCheckApi(): Observable<any> {
	//
	// 	var getResponse = this.http.get<any>(`https://ofku5f8mbb.execute-api.us-east-1.amazonaws.com/prod/get-degrees`, {
	// 		// headers: this.headers
	// 	});
	// 	return getResponse;
	// }
}

// createTimeSheetDetailActivityId(body): Observable<APIResponse<timeSheetDetailActivityDto>> {
//
// 	return this.http.post<APIResponse<timeSheetDetailActivityDto>>(this.baseUrl + this.commonEndPoint + '/AddTimeSheetDetailActivity', body, { headers: this.headers });

// }
