import { Injectable } from '@angular/core';
import { BaseApiService } from "./base-api.service";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from "./authentication.service";
import { Observable, throwError } from "rxjs";
import { APIResponse } from "../models/a-p-i-Response";
import { catchError, map } from 'rxjs/operators';
import { CategoryDto } from '../models/category';
import { environment } from 'src/environments/environment.prod';
//import { ToastrService } from 'ngx-toastr/toastr/to

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BaseApiService<CategoryDto>{
  commonEndPoint = 'Category';

  constructor(private _httpClient: HttpClient, public _authenticationService: AuthenticationService) {
    super(_httpClient, _authenticationService);

  }
  get(id: number): Observable<APIResponse<CategoryDto>> {
    return this.single(id);
  }

  getAll(queries?): Observable<APIResponse<CategoryDto[]>> {
    return this.all(queries);
  }

  getAllCategory(): Observable<APIResponse<CategoryDto[]>> {
    return this.http.get<APIResponse<CategoryDto[]>>(this.baseUrl + this.commonEndPoint + "/GetCategory", {
      headers: this.headers
    });

  }
  getSubAllCategory(): Observable<APIResponse<CategoryDto[]>> {
    return this.http.get<APIResponse<CategoryDto[]>>(this.baseUrl + this.commonEndPoint + "/GetSubCategory", {
      headers: this.headers
    });
  }
  categoryList1: CategoryDto[] = [];
  subCategoryList1: CategoryDto[] = [];
  getAllCategory2() {

    return this.http
      .get<APIResponse<CategoryDto>>(
        this.baseUrl + this.commonEndPoint + "/GetCategory",
        { headers: this.headers }
      )
      .pipe(
        map((res) => {
          if (environment.showLogs) console.log(res);
          if (res.successFlag) {
            let nD = res.data;
            this.categoryList1.push(nD);
          }
        })
      );
  }
  getSubAllCategory2() {

    return this.http
      .get<APIResponse<CategoryDto>>(
        this.baseUrl + this.commonEndPoint + "/GetSubCategory",
        { headers: this.headers }
      )
      .pipe(
        map((res) => {
          if (environment.showLogs) console.log(res);
          if (res.successFlag) {
            let nD = res.data;
            this.subCategoryList1.push(nD);
          }
        })
      );
  }

  getToCategory(): Observable<APIResponse<CategoryDto[]>> {
    return this.http.get<APIResponse<CategoryDto[]>>(this.baseUrl + this.commonEndPoint + "/GetTopCatrgory", {
      headers: this.headers
    });

  }
  subCategoryList: any;
  getAllSubCategory1() {
    return this.http.get(this.baseUrl + this.commonEndPoint + "/GetSubCategory", { headers: this.headers }).toPromise().then(res => this.subCategoryList = res as any[]
    );
  }
  // custome Apis
  getAllById(id: number) {
    return this.http.get<APIResponse<CategoryDto[]>>(this.baseUrl + this.commonEndPoint + "/catgoryDetailByid?id=" + id, {
      headers: this.headers
    });
  }
}
