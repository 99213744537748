import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'molla-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm!: FormGroup;
  paraId:any;
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private userService: AuthenticationService,
    private _route:ActivatedRoute,
    private modalService: ModalService,) {
    this.setresetFormDefault();
  }
  submitted = false;
  public errorMessage = "";
  ngOnInit(): void {
    this.parseQueryParams();
  }
  setresetFormDefault() {
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      userName: ['',],
      code: ['',],
    });
  }
  get fL() { return this.resetForm.controls; }
  login(defaultLogin: boolean) {

    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }
    this.errorMessage = "";
    this.userService.resetPassword(this.paraId?.email,this.resetForm.value.password, this.paraId?.code).subscribe((res: any) => {
      if(res.successFlag){
        this.router.navigateByUrl['/'];
        this.modalService.showLoginModal();
      }
    });
  }
  public parseQueryParams() {
    this._route.params.subscribe(
      (res) => {
        this.paraId = this._route.snapshot.queryParams;
        if(this.paraId){
          // this.paraId.email
        }
      },
      (err) => {
        this.router.navigateByUrl('');
      }
    );
  }
}
