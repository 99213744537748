import { Injectable } from '@angular/core';
import { BaseApiService } from "./base-api.service";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from "./authentication.service";
import { Observable, throwError } from "rxjs";
import { APIResponse } from "../models/a-p-i-Response";
import { catchError, map } from 'rxjs/operators';

import { productInfo } from '../models/dtos/productInfo';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseApiService<productInfo>{
  commonEndPoint = 'Product';
  productImgPath: string = '';
  constructor(private _httpClient: HttpClient, public _authenticationService: AuthenticationService) {

    super(_httpClient, _authenticationService);
    this.productImgPath = environment.productImgPath;

  }
  getGetProductInfobyPraram(params: any, perPage: number, initial = 0): Observable<APIResponse<productInfo[]>> {

    let temp = "?";
    if (initial > 0) {
      temp = "?shop=" + initial + "&";
    }
    // if (!initial.includes('?')) {
    // 	temp += '?';
    // }
    // temp += '?';
    for (let key in params) {
      temp += key + '=' + params[key] + '&';
    }

    if (!params.page) {
      temp += 'page=1';
    }

    if (!params.perPage) {
      temp += '&perPage=' + perPage;
    }

    temp += '&demo=' + environment.demo;

    return this.http.get<APIResponse<productInfo[]>>(this.baseUrl + this.commonEndPoint + "/GetProductInfoByShop" + temp, {
      headers: this.headers
    });
  }
  getProductByName(name: string): Observable<APIResponse<productInfo[]>> {
    return this.http.get<APIResponse<productInfo[]>>(this.baseUrl + this.commonEndPoint + "/GetProductByName?name=" + name, {
      headers: this.headers
    });
  }
  getOrderDetails(id: any): Observable<APIResponse<any[]>> {
    return this.http.get<APIResponse<any[]>>(this.baseUrl + this.commonEndPoint + "/GetOrderDetails?orderId=" + id, {
      headers: this.headers
    });
  }
}
