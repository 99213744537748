import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ModalService } from 'src/app/shared/services/modal.service';

@Component({
  selector: 'molla-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})

export class LoginModalComponent implements OnInit {
  singupForm!: FormGroup;
  loginForm!: FormGroup;
  forgotForm!: FormGroup;
  public errorMessage = "";
  submitted = false;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    public modalService: ModalService, private toasterService: ToastrService,
    private commonService:CommonService,
     private userService: AuthenticationService) {
    this.setLoginFormDefault();
    this.setSignFormDefault();
    this.setForgotFormDefault();
  }



  ngOnInit(): void {
  }

  setLoginFormDefault() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }
  get fL() { return this.loginForm.controls; }

  setSignFormDefault() {
    const nonWhitespaceRegExp: RegExp = new RegExp("\\S");
    this.singupForm = this.formBuilder.group({
      userName: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  get fS() { return this.singupForm.controls; }

  signup(defaultLogin: boolean) {

    this.submitted = true;
    if (this.singupForm.invalid) {
      this.toasterService.warning("Please enter correct data!", 'Warning');
      return;
    }

    this.errorMessage = "";
    // if(this.singupForm.value.password != this.singupForm.value.confirmPassword){
    //   this.toasterService.showWarning("Password and Confirm Password did not match!", 'Warning');
    //   return;
    // }
    var regexp = /^[a-zA-Z0-9-_]+$/;
    if (this.singupForm.value.userName.search(regexp) === -1) {
      this.toasterService.warning("Please enter correct UserName!", 'Warning');
      return;
    }
    this.commonService.startLoader();
    this.userService.register(this.singupForm.value).subscribe((res: any) => {
      this.commonService.stopLoader();
      if (res?.successFlag) {
        this.commonService.startLoader();

        this.userService.login(this.singupForm.value).subscribe((res: any) => {
          this.commonService.stopLoader();
          if (res?.successFlag) {
            // this.activeModal.close();
            // this.toasterService.success("Register successfully", "Welcome to Trivial ");
            // this.userService.setAuth(res.data);
            // this.gotoDashboard();
          }
        });
        // this.userService.setAuth(res.data);
        // this.router.navigate(['/login']);

      }
      else {
        this.errorMessage = res?.errorMessage;
        this.toasterService.error("Opsss", res?.message);

      }
    });

    // let data = this.singupForm.value.password
    // if (!defaultLogin) {
    //   const digest = data
    //   this.singupForm.value.password = digest;
    // }
  }
  login(defaultLogin: boolean) {

    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.errorMessage = "";
    this.commonService.startLoader();
    this.userService.login(this.loginForm.value).subscribe((res: any) => {
      this.commonService.stopLoader();
      if (res?.successFlag) {
        this.toasterService.success("Login successfully", "Welcome to Cybic Store ");
        // localStorage.setItem("User", res.data);
        this.modalService.closeModal();
        // this.userService.setAuth(res.data);
      }
      else {
        this.errorMessage = "Please enter Valid Username and Password.";
        this.toasterService.error("Please enter Valid Username and Password.", "Opsss");

      }
    });
    // let data = this.loginForm.value.password
    // if (!defaultLogin) {
    //   const digest = data
    //   this.loginForm.value.password = digest;
    // }
  }
  get fg() { return this.forgotForm.controls; }
  setForgotFormDefault() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required]],
    });
  }
  forgot(defaultLogin: boolean) {

    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    }
    this.errorMessage = "";
    this.commonService.startLoader();
    this.userService.forget(this.forgotForm.value.email).subscribe(
      (response)=>{
        this.commonService.stopLoader();
      },
      (error)=>{
        this.commonService.stopLoader();
      }
      // if (res?.successFlag) {
      //   this.toasterService.success("Login successfully", "Welcome to Trivial ");
      //   // this.userService.setAuth(res.data);
      // }
    );
  }
  closeModal() {
    let modal = document.querySelector('.login-modal') as HTMLElement;
    if (modal)
      modal.click();
  }
}
