<div class="search-container" (click)="onPageClick($event)">
    <main class="main home-page skeleton-body" style="background-color: #fafafa;">
        <div class="intro-section">
            <div class="container  mt-4">
                <div class="row">
                    <div class="col-md-2 d-none d-lg-block ">
                        <!-- <molla-category-menus>
                    </molla-category-menus> -->
                    </div>
                    <div class=" col-md-12 col-lg-8 col-12 mt-4">
                        <div class="intro-slider-container ">
                            <div class="search-product ">
                                <input type="text" class="form-control" [(ngModel)]="productName" (input)="search()"
                                    placeholder="Enter product name" />
                                <i class="fa-regular fa-xmark" (click)="close()"></i>
                            </div>
                            <div *ngIf="product.length > 0" class="search-product-result">
                                <div class="product-row" *ngFor="let item of product">
                                    <div class="profile">
                                        <molla-image class="product-img" src='{{productImgPath}}/{{item.imagePath}}'
                                            alt="Product"></molla-image>
                                    </div>
                                    <div class="content">
                                        <div class="detail">
                                            <h5>{{ item.itemName }}</h5>
                                            <h6>{{item.categoryName}}</h6>
                                            <p> {{item.productDetailName}}</p>
                                            <small> {{item.description | slice:0:250}}</small>
                                        </div>
                                        <div class="price">
                                            <!-- <small> {{item.description}}</small> -->
                                            <small> {{item.productModelName}}</small>
                                            <span class="out-price">{{currencyText}}{{ item.salePricePerItem }}</span>
                                            <!-- <div class="product-action position-relative visible">
                                            <button
                                                class="btn-product btn-cart text-uppercase text-dark text-decoration-none"
                                              >
                                                <span class="text-dark border-0">add to cart</span>
                                            </button>
                                        </div> -->
                                            <button (click)="addToCart($event,item)"
                                                class="btn btn-success btn-product btn-cart text-uppercase text-dark text-decoration-none">
                                                <!-- <i class="fa-regular fa-cart-shopping"></i> -->
                                                Add to cart
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="product.length  == 0 && searchData" class="search-product-result">
                                <div class="not-found-p">
                                    <img src="../../../../assets/images/not-found.gif" alt="">
                                    <h5>
                                        Products Not Found!
                                    </h5>
                                </div>
                            </div>
                            <owl-carousel *ngIf="false"
                                carouselClasses="intro-slider owl-theme owl-nav-inside row cols-1"
                                [options]="introSlider">
                                <div class="intro-slide bg-image d-flex align-items-center"
                                    style="background-color:#e8eded; background-image: url(assets/images/home/slider/slide-1.jpg);">
                                    <div class="intro-content">
                                        <!-- <h3 class="intro-subtitle font-size-normal text-dark font-weight-normal text-uppercase" contentAnim aniName="fadeInRightShorter">Your town best shop
                                    </h3> -->

                                        <!-- <h1 class="intro-title text-dark font-weight-bold mb-0" contentAnim aniName="fadeInRightShorter">Eat Something
                                        <br />Dried<br />
                                    </h1> -->

                                        <!-- <div class="intro-price text-dark font-weight-normal" contentAnim aniName="fadeInRightShorter">
                                        <sup class="font-weight-normal">from
                                            <span class="text-primary font-weight-normal">$</span>
                                        </sup>
                                        <span class="text-primary font-weight-bold">199<sup class="font-weight-normal">.99</sup>
                                        </span>
                                    </div> -->
                                        <!-- <br />
                                    <a routerLink="/shop/sidebar/4cols" class="btn btn-primary text-uppercase text-dark" contentAnim aniName="fadeInUpShorter">
                                        <span>Shop Now</span>
                                        <i class="icon-long-arrow-right"></i>
                                    </a> -->
                                    </div>
                                </div>

                                <div class="intro-slide bg-image d-flex align-items-center"
                                    style="background-color:#e8eded; background-image: url(assets/images/home/slider/slide-2.jpg); background-color: #222;">
                                    <div class="intro-content">
                                        <!-- <h3 class="intro-subtitle font-size-normal text-dark font-weight-normal text-uppercase" contentAnim aniName="fadeInRightShorter">New Arrivals</h3> -->

                                        <!-- <h1 class="intro-title text-white font-weight-bold mb-0" contentAnim aniName="fadeInRightShorter">
                                        Find Best-Dried
                                        <br>Natural Products
                                    </h1>
                                     -->
                                        <!-- <div class="intro-price text-white font-weight-normal position-relative mb-3" contentAnim aniName="fadeInRightShorter">
                                         <div class="position-relative">
                                            <sup class="font-weight-normal">Orignal</sup>
                                            <span class="font-weight-bold ml-n2">
                                                100
                                                <sup class="font-weight-normal">%</sup>
                                            </span>
                                        </div>
                                        <img src="assets/images/home/slider/slide-2-brushpng.png" class="position-absolute w-auto h-auto" alt="" width="85"
                                            height="35">
                                    </div>

                                    <a routerLink="/shop/sidebar/4cols" class="btn btn-primary text-uppercase text-dark" contentAnim aniName="fadeInUpShorter">
                                        <span>Shop Now</span>
                                        <i class="icon-long-arrow-right"></i>
                                    </a> -->
                                    </div>
                                </div>

                                <div class="intro-slide bg-image d-flex align-items-center"
                                    style="background-color:#e8eded; background-image: url(assets/images/home/slider/slide-3.jpg); background-color: #fff;">
                                    <div class="intro-content">
                                        <!-- <h3 class="intro-subtitle font-size-normal text-white font-weight-normal text-uppercase" contentAnim aniName="fadeInRightShorter">Design special</h3>

                                    <h1 class="intro-title text-white font-weight-bold mt-0 my-2" contentAnim aniName="fadeInRightShorter">
                                        Your First Decision
                                        <br>Dried Organic Products
                                    </h1>

                                    <div class="intro-text text-secondary font-weight-lighter mb-4" contentAnim aniName="fadeInRightShorter">
                                        Extra 25% Off
                                    </div>

                                    <a routerLink="/shop/sidebar/4cols" class="btn btn-primary text-uppercase text-dark" contentAnim aniName="fadeInUpShorter">
                                        <span>Shop Now</span>
                                        <i class="icon-long-arrow-right"></i>
                                    </a> -->
                                    </div>
                                </div>
                            </owl-carousel>
                        </div>
                    </div>

                    <!-- <div class="col-lg-5cols col-md-3 col-12 mb-md-0 mb-2">
                    <div class="banner banner-overlay bg-image h-100 mb-0" style="background-color: #f1f1f1; background-image: url(assets/images/home/banners/banner-1.jpg); ">
                        <div class="banner-content position-relative pt-0 pb-md-7 d-flex flex-column">
                            <div class="title text-center text-uppercase text-dark font-weight-bold mb-0">
                                Highest Level Of
                                <br>Dry Fruits<br>
                            </div>
                             <div class="price text-center">
                                <br />
                                <a routerLink="/shop/sidebar/4cols" class="btn btn-primary text-uppercase text-dark" contentAnim aniName="fadeInUpShorter">
                                    <span>Shop Now</span>
                                    <i class="icon-long-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div> -->
                </div>
            </div>
        </div>

        <!-- <div class="container icon-boxes-section">
        <div class="icon-boxes-container py-4 bg-lighter mb-2 mt-2">
            <owl-carousel carouselClasses="carousel-simple owl-theme row cols-1 cols-md-2 cols-lg-3 cols-xl-4" [options]="infoSlider">
                <div class="mb-lg-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    <span class="icon-box-icon mb-0">
                        <i class="icon-truck py-2 pt-0 second-primary-color"></i>
                    </span>
                    <div class="icon-box-content">
                        <h3 class="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase second-primary-color">Payment &amp; Delivery</h3>
                        <p class="font-weight-light second-primary-color">Free shipping for orders over $50</p>
                    </div>
                </div>
                <div class="mb-lg-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    <span class="icon-box-icon text-dark mb-0">
                        <i class="icon-rotate-left py-2 pt-0 second-primary-color"></i>
                    </span>
                    <div class="icon-box-content">
                        <h3 class="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase second-primary-color">Return &amp; Refund</h3>
                        <p class="font-weight-light second-primary-color">Free 100% money back guarantee</p>
                    </div>
                </div>
                <div class="mb-lg-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    <span class="icon-box-icon text-dark mb-0">
                        <i class="icon-life-ring py-2 pt-0 second-primary-color"></i>
                    </span>
                    <div class="icon-box-content">
                        <h3 class="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase second-primary-color">Quality Support</h3>
                        <p class="font-weight-light second-primary-color">Alway online feedback 24/7</p>
                    </div>
                </div>
                <div class="mb-lg-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                    <span class="icon-box-icon text-dark mb-0">
                        <i class="icon-envelope py-2 pt-0 second-primary-color"></i>
                    </span>
                    <div class="icon-box-content">
                        <h3 class="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase second-primary-color">JOIN OUR NEWSLETTER</h3>
                        <p class="font-weight-light second-primary-color">10% off by subscribing to our newsletter</p>
                    </div>
                </div>
            </owl-carousel>
        </div>
        <hr class="mt-2 mb-0">
    </div> -->

        <!-- <molla-top-category [topSubCategory]="subcategoryList" [catgloaded]="catgloaded"></molla-top-category> -->

        <!-- <molla-trendy-collection [products]="topProducts" [loaded]="loaded"></molla-trendy-collection> -->

        <!-- <div class="container" contentAnim aniName="fadeInUpShorter">
        <div class="row">
            <div class="col-12" style="display: inline-block;">
                <molla-electronics-collection [products]="elecProducts" [loaded]="loaded">
                </molla-electronics-collection>
            </div>
        </div>
    </div>

    <div class="container banner-group-2">
        <div class="row row-sm">
            <div class="col-md-6" contentAnim aniName="fadeInLeftShorter">
                <div class="banner banner-overlay bg-image"
                    style="background-image: url(assets/images/home/banners/banner-5.jpg);">
                    <div class="banner-content banner-content-left position-relative">
                        <h4 class="banner-subtitle letter-spacing-normal font-size-normal font-weight-light mb-1"
                            style="color: #000;">
                            <a routerLink="/shop/sidebar/3cols">Spring Sale is Coming</a>
                        </h4>

                        <h3 class="banner-title font-weight-bold mb-0">
                            <a routerLink="/shop/sidebar/3cols">All Dried Fruits
                                <br>Discount</a>
                        </h3>

                        <h4 class="banner-text text-secondary font-weight-normal">15% off</h4>
                        <a routerLink="/shop/sidebar/3cols"
                            class="btn-product font-size-normal text-uppercase text-dark text-decoration-none btn-rounded">Shop
                            Now&nbsp;&nbsp;<i class="icon-long-arrow-right d-inline-block"></i>
                        </a>
                    </div>

                </div>
            </div>
            <div class="col-md-6" contentAnim aniName="fadeInRightShorter">
                <div class="banner banner-overlay bg-image"
                    style="background-image: url(assets/images/home/banners/banner-6.jpg);">
                    <div class="banner-content banner-content-left position-relative">
                        <h4
                            class="banner-subtitle letter-spacing-normal font-size-normal text-white font-weight-light mb-1">
                            <a routerLink="/shop/sidebar/3cols">Amazing Value</a>
                        </h4>

                        <h3 class="banner-title text-white font-weight-bold mb-0">
                            <a routerLink="/shop/sidebar/3cols">Got Dried Out
                                <br>Natural Products Accessible</a>
                        </h3>

                        <h4 class="banner-text text-secondary font-weight-normal">We Wre There For You</h4>
                        <a routerLink="/shop/sidebar/3cols"
                            class="btn-product font-size-normal text-uppercase text-dark text-decoration-none btn-rounded">Shop
                            Now&nbsp;&nbsp;<i class="icon-long-arrow-right d-inline-block"></i>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div> -->

        <!-- <molla-top-product [products]="products"  [otherproducts]="otherproducts" *ngIf="products.length" [loaded]="topProductloaded"></molla-top-product> -->
        <molla-febric-collection [products]="febricProducts" [otherproducts]="otherfebricProducts"
            *ngIf="febricProducts.length" [loaded]="febricloaded"></molla-febric-collection>

        <molla-fashion-collection [products]="garmentsProducts" [otherproducts]="othergarmentsProducts"
            *ngIf="garmentsProducts.length" [loaded]="garmentsloaded"></molla-fashion-collection>
        <molla-electronics-collection [products]="electronicProducts" [otherproducts]="otherelectronicProducts"
            *ngIf="electronicProducts.length" [loaded]="electronicloaded"></molla-electronics-collection>
        <molla-general-shop [products]="generalProducts" [otherproducts]="othergeneralProducts"
            *ngIf="generalProducts.length" [loaded]="generalloaded"></molla-general-shop>
        <molla-electric-collention [products]="elecProducts" [otherproducts]="otherelecProducts"
            *ngIf="elecProducts.length" [loaded]="electricloaded"></molla-electric-collention>



        <!-- <molla-new-collection [products]="products" [loaded]="loaded"></molla-new-collection> -->

        <!-- <div class="container banner-group-2">
        <div class="row row-sm">
            <div class="col-md-6" contentAnim aniName="fadeInLeftShorter">
                <div class="banner banner-overlay bg-image"
                    style="background-image: url(assets/images/home/banners/contact.jpg);height: 392px;">
                    <div class="banner-content banner-content-left position-relative">
                        <h4 class="banner-subtitle letter-spacing-normal font-size-normal font-weight-light mb-1"
                            style="color:#fff">
                            <a routerLink="/shop/sidebar/3cols">What You Looking For</a>
                        </h4>

                        <h3 class="banner-title font-weight-bold mb-0" style="color:#fff">
                            <a routerLink="/shop/sidebar/3cols">GET IN TOUCH?
                                <br>&amp; How Can We Help You,Contact Us What You Want.</a>
                        </h3>

                        <h4 class="banner-text text-secondary font-weight-normal">Send You FeedBack</h4>
                        <a routerLink="/pages/contact" class="btn-product font-size-normal text-uppercase text-dark text-decoration-none btn-rounded">Contact Us&nbsp;&nbsp;<i class="icon-long-arrow-right d-inline-block"></i>
                        </a>
                    </div>

                </div>
            </div>
            <div class="col-lg-6">
                <h2 class="title mb-1">Got Any Questions?</h2>
                <p class="mb-2">Use the form below to get in touch with the sales team</p>

                <form action="#" class="contact-form mb-3">
                    <div class="row">
                        <div class="col-sm-6">
                            <label for="cname" class="sr-only">Name</label>
                            <input type="text" class="form-control" id="cname" placeholder="Name *" required>
                        </div>

                        <div class="col-sm-6">
                            <label for="cemail" class="sr-only">Email</label>
                            <input type="email" class="form-control" id="cemail" placeholder="Email *" required>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <label for="cphone" class="sr-only">Phone</label>
                            <input type="tel" class="form-control" id="cphone" placeholder="Phone">
                        </div>

                        <div class="col-sm-6">
                            <label for="csubject" class="sr-only">Subject</label>
                            <input type="text" class="form-control" id="csubject" placeholder="Subject">
                        </div>
                    </div>

                    <label for="cmessage" class="sr-only">Message</label>
                    <textarea class="form-control" cols="30" rows="4" id="cmessage" required
                        placeholder="Message *"></textarea>

                    <button type="submit" class="btn btn-outline-primary-2 btn-minwidth-sm">
                        <span>SUBMIT</span>
                        <i class="icon-long-arrow-right"></i>
                    </button>
                </form>
            </div>
        </div>
    </div> -->


        <!-- <div class="bg-white brand-section pb-4">
        <div class="container" contentAnim>
            <owl-carousel carouselClasses="owl-simple brands-carousel owl-theme" [options]="brandSlider">
                <a href="javascript:;" class="brand">
                    <img src="assets/images/brands/1.png" alt="Brand Name">
                </a>

                <a href="javascript:;" class="brand">
                    <img src="assets/images/brands/2.png" alt="Brand Name">
                </a>

                <a href="javascript:;" class="brand">
                    <img src="assets/images/brands/3.png" alt="Brand Name">
                </a>

                <a href="javascript:;" class="brand">
                    <img src="assets/images/brands/4.png" alt="Brand Name">
                </a>

                <a href="javascript:;" class="brand">
                    <img src="assets/images/brands/5.png" alt="Brand Name">
                </a>

                <a href="javascript:;" class="brand">
                    <img src="assets/images/brands/6.png" alt="Brand Name">
                </a>

                <a href="javascript:;" class="brand">
                    <img src="assets/images/brands/7.png" alt="Brand Name">
                </a>
            </owl-carousel>
            <hr class="mb-0">
        </div>
    </div> -->

        <!-- <molla-blog-collection [posts]="posts" [loaded]="loaded"></molla-blog-collection> -->
    </main>
</div>