<div class="modal-body">
    <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true"><i class="icon-close"></i></span>
    </button>

    <div class="form-box">
        <div class="form-tab nav-fill">
            <ul ngbNav #nav="ngbNav" class="justify-content-center nav nav-pills">
                <li ngbNavItem>
                    <a ngbNavLink>Sign In</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="loginForm" (submit)="login(false)" novalidate>
                            <fieldset class="form-group position-relative has-icon-left">
                                <label for="singin-email">Email address *</label>
                                <input type="text" class="form-control" formControlName="email"
                                    placeholder="Enter Email"
                                    [ngClass]="{ 'is-invalid': submitted && fL['email'].errors }" />
                                <div *ngIf="submitted && fL['email'].errors" class="invalid-feedback">
                                    <div *ngIf="fL['email'].errors['required']">
                                        This field is required</div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group position-relative has-icon-left">
                                <label for="singin-password">Password *</label>
                                <input type="password" class="form-control" formControlName="password"
                                    placeholder="Enter Password"
                                    [ngClass]="{ 'is-invalid': submitted && fL['password'].errors }" />
                                <div *ngIf="submitted && fL['password'].errors" class="invalid-feedback">
                                    <div *ngIf="fL['password'].errors['required']">
                                        This field is required</div>
                                </div>
                            </fieldset>
                            <div class="form-footer">
                                <button type="submit" class="btn btn-outline-primary-2">
                                    <span>LOG IN</span>
                                    <i class="icon-long-arrow-right"></i>

                                </button>

                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="signin-remember">
                                    <label class="custom-control-label" for="signin-remember">Remember Me</label>
                                </div>

                                <!-- <a href="javascript:;" (click)="showLoginModal($event)" class="forgot-link">Forgot Your Password?</a> -->
                            </div>
                        </form>

                        <!-- <div class="form-choice">
                            <p class="text-center">or sign in with</p>
                            <div class="row">
                                <div class="col-sm-6">
                                    <a href="javascript:;" class="btn btn-login btn-g">
                                        <i class="icon-google"></i>
                                        Login With Google
                                    </a>
                                </div>
                                <div class="col-sm-6">
                                    <a href="javascript:;" class="btn btn-login btn-f">
                                        <i class="icon-facebook-f"></i>
                                        Login With Facebook
                                    </a>
                                </div>
                            </div>
                        </div> -->
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink>Register</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="singupForm" (submit)="signup(false)" novalidate>
                            <fieldset class="form-group position-relative has-icon-left">
                                <label for="singin-email">Your email address *</label>
                                <input type="email" email="true" class="form-control" formControlName="email"
                                    placeholder="Enter Email"
                                    [ngClass]="{ 'is-invalid': submitted && fS['email'].errors }" />
                                <div *ngIf="submitted && fS['email'].errors" class="invalid-feedback">
                                    <div *ngIf="fS['email'].errors['required']">
                                        This field is required</div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group position-relative has-icon-left">
                                <label for="singin-email">Your Username *</label>
                                <input type="text" class="form-control" formControlName="userName"
                                    placeholder="Enter Username"
                                    [ngClass]="{ 'is-invalid': submitted && fS['userName'].errors }" />
                                <div *ngIf="submitted && fS['userName'].errors" class="invalid-feedback">
                                    <div *ngIf="fS['userName'].errors['required']">
                                        This field is required</div>
                                </div>
                                <div *ngIf="singupForm.hasError('whitespace')">Please enter valid UserName</div>
                            </fieldset>
                            <fieldset class="form-group position-relative has-icon-left">
                                <label for="register-password">Password *</label>
                                <input type="password" class="form-control" formControlName="password"
                                    placeholder="Enter Password"
                                    [ngClass]="{ 'is-invalid': submitted && fS['password'].errors }" />
                                <div *ngIf="submitted && fS['password'].errors" class="invalid-feedback">
                                    <div *ngIf="fS['password'].errors['required']">
                                        This field is required</div>
                                </div>
                            </fieldset>

                            <div class="form-footer">
                                <button type="submit" class="btn btn-outline-primary-2">
                                    <span>SIGN UP</span>
                                    <i class="icon-long-arrow-right"></i>
                                </button>

                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="register-policy" required>
                                    <label class="custom-control-label" for="register-policy">I agree to the privacy
                                        policy</label>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink>Forgot Password</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="forgotForm" (submit)="forgot(false)" novalidate>
                            <fieldset class="form-group position-relative has-icon-left">
                                <label for="singin-email">Email address *</label>
                                <input type="text" class="form-control" formControlName="email"
                                    placeholder="Enter Email"
                                    [ngClass]="{ 'is-invalid': submitted && fg['email'].errors }" />
                                <div *ngIf="submitted && fg['email'].errors" class="invalid-feedback">
                                    <div *ngIf="fg['email'].errors['required']">
                                        This field is required</div>
                                </div>
                            </fieldset>
                            <div class="form-footer">
                                <button type="submit" class="btn btn-outline-primary-2">
                                    <span>Submit</span>
                                    <i class="icon-long-arrow-right"></i>
                                </button>
                                <!-- <a href="javascript:;" class="forgot-link" >Login / Signup</a> -->
                            </div>
                        </form>

                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</div>
