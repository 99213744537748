<div class="container mb-5 categories-dropdown">
    <div class="title-div">
    <h3>All Categories</h3>
</div>
    <div class="row">
        <div class="col-lg-2 col-md-3" *ngFor="let item of categoryList">
            <h5>
                <li>
                    <a routerLink="/shop/sidebar/4cols" class="text-dark" [queryParams]="{category: item.id}">
                        {{item.categoryName}}</a>
                </li>
            </h5>
        </div>
    </div>
</div>
