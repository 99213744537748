export const environment = {
  production: true,
  demo: 'demo26',
  SERVER_URL: 'https://api.cybicstore.com',
  // SERVER_URL: 'https://localhost:44370',
  hmr: false,
  apiUrl: 'https://api.cybicstore.com',
  // apiUrl: 'https://localhost:44370',
  serverApiUrl: 'https://api.cybicstore.com',
  // serverApiUrl: 'https://localhost:44370',
  showLogs: true,
  logErrors: true,
  perPage : 12,
  // productImgPath:"http://182.180.142.211:8019/uploads/",

  productImgPath:"https://admin.cybicstore.com/Uploads",
  // productImgPath:"http://localhost:46663/Uploads",
  // productImgPath:"Upload/Product",
  subCategoryImgPath:"Upload/SubCategory",
  currencyText:"RS: "
};


// export const environment = {
//   production: true,
//   demo: 'demo26',
//   SERVER_URL: 'https://d-themes.com/angular/molla/server',
//   hmr: false,
//   apiUrl: 'http://eapi.alnaseeb.com.pk/',
//   serverApiUrl: 'http://eapi.alnaseeb.com.pk/',
//   showLogs: true,
//   logErrors: true,
//   perPage : 12,
// };
