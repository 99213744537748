<div class="container mb-5 electronics fashion">
    <div class="bg-lighter trending-products">
        <div class="heading heading-flex">
            <div class="heading-left" contentAnim aniName="blurIn">
                <h2 class="title font-weight-bold mb-1">Clothes Product</h2>
            </div>

            <!-- <div class="heading-right">
                <ul class="nav nav-pills justify-content-center mr-n3" role="tablist" tabClick>
                    <li class="nav-item">
                        <a class="nav-link active" href="" role="tab" (click)="nav.select('best')">Hazelnuts 1</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="" role="tab" (click)="nav.select('trending')">Almond</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="" role="tab" (click)="nav.select('woman')">Dates</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="" role="tab" (click)="nav.select('man')">Raisins</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="" role="tab" (click)="nav.select('shoes')">Cashew</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="" role="tab" (click)="nav.select('accessories')">Apricot</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="" role="tab" (click)="nav.select('accessories')">Walnuts</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="" role="tab" (click)="nav.select('accessories')">Plums</a>
                    </li>

                </ul>
            </div> -->
        </div>

        <div class="position-relative">
            <ul ngbNav #nav="ngbNav" class="tab-content tab-content-carousel" #tab [destroyOnHide]="false">
                <li ngbNavItem="best">
                    <ng-template ngbNavContent>
                        <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height" *ngIf="!loaded else elseBlock">
                            <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                        </owl-carousel>
                        <ng-template #elseBlock>
                            <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                                <molla-product-twelve [product]="product" *ngFor="let product of products | slice:0 : 15"></molla-product-twelve>
                                <div class="product d-flex flex-column overflow-hidden" routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 7}" *ngIf="products.length == 15">
                                  <figure class="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                                   <h1 class="mx-4 px-4  mt-4 text-center">  More  <span class="mx-4 px-4  mt-4"><i class="icon-arrow-right mx-4 px-4 mt-4"></i></span> </h1>
                                  </figure>
                              </div>
                            </owl-carousel>
                        </ng-template>
                    </ng-template>
                </li>
                <!-- <li ngbNavItem="trending">
                    <ng-template ngbNavContent>
                        <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height" *ngIf="!loaded else elseBlock">
                            <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                        </owl-carousel>
                        <ng-template #elseBlock>
                            <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                                <molla-product-twelve [product]="product" *ngFor="let product of products"></molla-product-twelve>
                            </owl-carousel>
                        </ng-template>
                    </ng-template>
                </li> -->
                <!-- <li ngbNavItem="woman">
                    <ng-template ngbNavContent>
                        <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height" *ngIf="!loaded else elseBlock">
                            <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                        </owl-carousel>
                        <ng-template #elseBlock>
                            <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                                <molla-product-twelve [product]="product" *ngFor="let product of products"></molla-product-twelve>
                            </owl-carousel>
                        </ng-template>
                    </ng-template>
                </li> -->
                <!-- <li ngbNavItem="man">
                    <ng-template ngbNavContent>
                        <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height" *ngIf="!loaded else elseBlock">
                            <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                        </owl-carousel>
                        <ng-template #elseBlock>
                            <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                                <molla-product-twelve [product]="product" *ngFor="let product of products"></molla-product-twelve>
                            </owl-carousel>
                        </ng-template>
                    </ng-template>
                </li>
                <li ngbNavItem="shoes">
                    <ng-template ngbNavContent>
                        <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height" *ngIf="!loaded else elseBlock">
                            <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                        </owl-carousel>
                        <ng-template #elseBlock>
                            <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                                <molla-product-twelve [product]="product" *ngFor="let product of products "></molla-product-twelve>
                            </owl-carousel>
                        </ng-template>
                    </ng-template>
                </li> -->
                <!-- <li ngbNavItem="accessories">
                    <ng-template ngbNavContent>
                        <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height" *ngIf="!loaded else elseBlock">
                            <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                        </owl-carousel>
                        <ng-template #elseBlock>
                            <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                                <molla-product-twelve [product]="product" *ngFor="let product of products"></molla-product-twelve>
                            </owl-carousel>
                        </ng-template>
                    </ng-template>
                </li>   -->
            </ul>
            <div [ngbNavOutlet]="nav"></div>
            <ul ngbNav #nav1="ngbNav" class="tab-content tab-content-carousel" #tab [destroyOnHide]="false">
              <li ngbNavItem="best">
                  <ng-template ngbNavContent>
                      <owl-carousel [options]="sliderOption"
                          carouselClasses="electronic-carousel owl-simple carousel-equal-height"
                          *ngIf="!loaded else elseBloc">
                          <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                      </owl-carousel>
                      <ng-template #elseBloc>
                          <owl-carousel [options]="sliderOption"
                              carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                              <molla-product-twelve [product]="product"
                                  *ngFor="let product of otherproducts | slice:0 : 15"></molla-product-twelve>
                              <div class="product d-flex flex-column overflow-hidden" routerLink="/shop/sidebar/4cols"
                                  [queryParams]="{shop: 0}" *ngIf="products.length == 15">
                                  <figure
                                      class="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                                      <h1 class="mx-4 px-4  mt-4 text-center"> More <span class="mx-4 px-4  mt-4"><i
                                                  class="icon-arrow-right mx-4 px-4 mt-4"></i></span> </h1>
                                  </figure>
                              </div>
                          </owl-carousel>
                      </ng-template>
                  </ng-template>
              </li>

          </ul>
            <div [ngbNavOutlet]="nav1" *ngIf="otherproducts.length > 0"> </div>
            <div class="bottom-end-btn">
              <button class="btn-product text-uppercase text-dark text-decoration-none"
              routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 7}" >More</button>
          </div>
            <!-- <div class="banner banner-overlay mb-0 mt-2 mt-md-0 bg-image position-md-relative position-absolute" style="background-image: url(assets/images/home/banners/banner-bg-2.jpg)">
                <span class="product-label label-sale">CLEARANCE</span>
                <div class="banner-content position-relative d-flex flex-column">
                    <h3 class="banner-title text-white font-weight-bold mb-2 text-left">Women's
                    <br />Sportswear &amp; Joggers</h3>
                    <a routerLink="/shop/sidebar/4cols" class="btn-product text-decoration-none text-uppercase text-dark btn-rounded" title="">
                        <span class="text-dark border-0">Discover Now&nbsp;
                            <i class="icon-long-arrow-right d-inline-block"></i>
                        </span>
                    </a>
                </div>
            </div> -->
        </div>
    </div>
</div>
