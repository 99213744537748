<header class="header header-26 header-intro-clearance position-fixed">
  <!-- <div class="header-top">
        <div [class]="containerClass">
            <div class="header-left">
                <a href="tel:03111111456" class="font-weight-normal">
                    <i class="icon-phone h6 second-primary-color"></i>Call: 03-111-111-456
                </a>
            </div>

            <div class="header-right font-weight-normal">


            </div>
        </div>
    </div> -->

  <div class="header-middle" style="background-color: #241335;height: 89px;
    margin-top: -22px;">
    <div [class]="containerClass">
      <div class="header-left">
        <molla-mobile-button></molla-mobile-button>

        <a routerLink="/" class="logo" style="cursor: pointer;">
          <!-- <img src="assets/images/home/logo.png" alt="Molla Logo" width="20%" height="27"> -->
          <img src="assets/images/home/cybic.jpg" alt="Molla Logo" width="50" height="30">
        </a>
        <h3 routerLink="/" style="color:#f7f7f7 !important;cursor: pointer;"><b>Cybic Store</b></h3>
      </div>

      <div class="header-center">
        <molla-main-menu></molla-main-menu>

      </div>

      <div class="header-right">
        <!-- <div _ngcontent-noy-c44="" class="wishlist"><a _ngcontent-noy-c44="" routerlink="/shop/wishlist" title="Wishlist" href="/shop/wishlist"><div _ngcontent-noy-c44="" class="icon"><i _ngcontent-noy-c44="" class="icon-envelope"></i><span _ngcontent-noy-c44="" class="wishlist-count">0</span></div><p _ngcontent-noy-c44="">Chat</p></a></div> -->
        <molla-wishlist-menu></molla-wishlist-menu>
        <molla-cart-menu></molla-cart-menu>
        <div *ngIf="userCheck ==null || userCheck =='' || !userCheck" class="login pl-4 mr-0">
          <a href="javascript:;" (click)="showLoginModal($event)" class="desktop-btn" style="color:#f7f7f7 !important">
            Sign in /
            Sign up
          </a>
          <a href="javascript:;" (click)="showLoginModal($event)" class="mobile-btn" style="color:#f7f7f7 !important">
            <i class="fa-solid fa-user"></i>
          </a>
        </div>
        <!--
                <li *ngIf="userCheck !=null && userCheck !='' && userCheck" class="login pl-4 mr-0">
                    <a href="javascript:;" (click)="logout()" style="color:#f7f7f7 !important">Logout</a>
                </li> -->
        <div class="user-drop-down" *ngIf="userCheck !=null && userCheck !='' && userCheck">
          <div class="dropdown">
            <button class=" dropdown-toggle" type="button" data-toggle="dropdown">
              <div class="user-profile">{{makeAvatar(userCheck?.email)}}</div>
            </button>
            <ul class="dropdown-menu dropdown-menu-right">
              <li><a (click)="profile()">{{getName(userCheck?.email)}}</a></li>
              <li><a (click)="profile()">Setting</a></li>
              <li><a (click)="logout()">Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sticky-wrapper" [style.height]="utilsService.isSticky? utilsService.stickyHeight + 'px' : '' ">
    <div class="header-bottom sticky-header" [class.fixed]="utilsService.isSticky">
      <div [ngClass]="containerClass">
        <!-- <div class="col-2"></div>
                <div class="col-8 mb-2">
                    <molla-header-search class="w-100"></molla-header-search>
                </div> -->

        <!-- <div class="header-right overflow-hidden">
                    <ul class="top-menu">
                        <li>
                            <a href="javascript:;">Links</a>
                            <ul>
                                <li>
                                    <div class="header-dropdown">
                                        <a href="javascript:;">USD</a>
                                        <div class="header-menu">
                                            <ul>
                                                <li><a href="javascript:;">Eur</a></li>
                                                <li><a href="javascript:;">Usd</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="header-dropdown">
                                        <a href="javascript:;">English</a>
                                        <div class="header-menu">
                                            <ul>
                                                <li><a href="javascript:;">English</a></li>
                                                <li><a href="javascript:;">French</a></li>
                                                <li><a href="javascript:;">Spanish</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </li>
                    </ul>
                </div> -->
      </div>
    </div>
  </div>
  <div _ngcontent-cur-c47="" class="container" style="margin: 0px, 0px;
        padding: 0px;
        width: 100%;
        white-space: nowrap;">
    <ul _ngcontent-cur-c46="" class="menu mobile-nav">
      <li>
        <a class="text-dark" routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 7}">Garments</a>
        <ul style="margin-top: -20px;">
          <li class="drop-search">
            <div class="input-group">
              <input type="text" class="form-control" [(ngModel)]="garments" (input)="changeGarmentCategory()"
                placeholder="Search Product">
              <div class="input-group-prepend">
                <div class="input-group-text" (click)="assignAllGarment()">x</div>
              </div>
            </div>
          </li>
          <li *ngFor="let item of garmentsCategoryList | slice:0 : 15" routerLink="/shop/sidebar/4cols"
            [queryParams]="{category: item.id}">
            <a class="text-dark">{{item.categoryName}}</a>
          </li>
        </ul>
      </li>
      <li>
        <a class="text-dark" routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 6}">Electric</a>
        <ul style="margin-top: -20px;">
          <li class="drop-search">
            <div class="input-group">
              <input type="text" class="form-control" [(ngModel)]="electric" (input)="changeElectricCategory()"
                placeholder="Search Product">
              <div class="input-group-prepend">
                <div class="input-group-text" (click)="assignAllElectric()">x</div>
              </div>
            </div>
          </li>
          <li *ngFor="let item of electricCategoryList | slice:0 : 15" routerLink="/shop/sidebar/4cols"
            [queryParams]="{category: item.id}">
            <a class="text-dark">{{item.categoryName}}</a>
          </li>
        </ul>
      </li>

      <li>
        <a class="text-dark" routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 8}">Electronic</a>
        <ul style="margin-top: -20px;">
          <li class="drop-search">
            <div class="input-group">
              <input type="text" class="form-control" [(ngModel)]="electronic" (input)="changeElectronicCategory()"
                placeholder="Search Product">
              <div class="input-group-prepend">
                <div class="input-group-text" (click)="assignAllElectronic()">x</div>
              </div>
            </div>
          </li>
          <li *ngFor="let item of ElectronicCategoryList | slice:0 : 15" routerLink="/shop/sidebar/4cols"
            [queryParams]="{category: item.id}">
            <a class="text-dark">{{item.categoryName}}</a>
          </li>
        </ul>
      </li>
      <li>
        <a class="text-dark" routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 10002}">Fabric</a>
        <ul style="margin-top: -20px;">
          <li class="drop-search">
            <div class="input-group">
              <input type="text" class="form-control" [(ngModel)]="fabric" (input)="changeFabricCategory()"
                placeholder="Search Product">
              <div class="input-group-prepend">
                <div class="input-group-text" (click)="assignAllfabric()">x</div>
              </div>
            </div>
          </li>
          <li *ngFor="let item of fabricCategoryList | slice:0 : 15" routerLink="/shop/sidebar/4cols"
            [queryParams]="{category: item.id}">
            <a class="text-dark">{{item.categoryName}}</a>
          </li>
        </ul>
      </li>
      <li>
        <a class="text-dark" routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 20002}">General</a>
        <ul style="margin-top: -20px;">
          <li class="drop-search">
            <div class="input-group">
              <input type="text" class="form-control" [(ngModel)]="general" (input)="changeGeneralCategory()"
                placeholder="Search Product">
              <div class="input-group-prepend">
                <div class="input-group-text" (click)="assignAllgeneral()">x</div>
              </div>
            </div>
          </li>
          <li *ngFor="let item of generalCategoryList | slice:0 : 15" routerLink="/shop/sidebar/4cols"
            [queryParams]="{category: item.id}">
            <a class="text-dark">{{item.categoryName}}</a>
          </li>
        </ul>
      </li>
      <li>
        <a class="text-dark" routerLink="/pages/about">Track Your Parcel</a>
      </li>
      <!-- <li>
                <a routerLink="/shop/sidebar/4cols" class="text-dark" [queryParams]="{category: 'home-appliance'}">
                    Hazelnuts</a>
            </li>
            <li>
                <a routerLink="/shop/sidebar/4cols" class="text-dark" [queryParams]="{category: 'beauty'}">
                    Almond</a>
            </li>
            <li>
                <a routerLink="/shop/sidebar/4cols" class="text-dark" [queryParams]="{category: 'shoes'}">
                    Dates</a>
            </li>
            <li>
                <a routerLink="/shop/sidebar/4cols" class="text-dark" [queryParams]="{category: 'travel'}">
                    Raisins</a>
            </li>
            <li>
                <a routerLink="/shop/sidebar/4cols" class="text-dark" [queryParams]="{category: 'phone'}">
                    Cashew</a>
            </li>
            <li>
                <a routerLink="/shop/sidebar/4cols" class="text-dark" [queryParams]="{category: 'tv'}">
                    Apricot</a>
            </li>
            <li>
                <a routerLink="/shop/sidebar/4cols" class="text-dark" [queryParams]="{category: 'gift'}">
                    Walnuts</a>
            </li>
            <li>
                <a routerLink="/shop/sidebar/4cols" class="text-dark" [queryParams]="{category: 'gift'}">
                    Plums</a>
            </li>
            <li>
                <a routerLink="/shop/sidebar/4cols" class="text-dark" [queryParams]="{category: 'gift'}">
                    Pistachio</a>
            </li>
            <li>
                <a routerLink="/shop/sidebar/4cols" class="text-dark" [queryParams]="{category: 'gift'}">
                    Injeer</a>
            </li>
            <li>
                <a routerLink="/shop/sidebar/4cols" class="text-dark" [queryParams]="{category: 'gift'}">
                    Candy & Choco</a>
            </li> -->
    </ul>
  </div>
</header>
