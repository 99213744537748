import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'molla-user-account-details',
  templateUrl: './user-account-details.component.html',
  styleUrls: ['./user-account-details.component.scss']
})
export class UserAccountDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
