<div class="blog-section bg-white py-2 pb-4">
    <div class="container">
        <div class="heading heading-flex">
            <div class="heading-left">
                <h2 class="title mb-0 font-weight-bold" contentAnim aniName="blurIn">Top Product</h2>
            </div>
            <div class="heading-right">
                <a routerLink="/blog/classic" class="title-link font-size-normal text-uppercase font-weight-normal">View More Posts
                    <i class="icon-long-arrow-right"></i>
                </a>
            </div>
        </div>
        <owl-carousel carouselClasses="owl-simple carousel-with-shadow" [options]="sliderOption"
            *ngIf="!loaded else loadedBlock">
            <div class="skel-pro" *ngFor="let i of [1,2,3,4,5]"></div>
        </owl-carousel>
        <ng-template #loadedBlock>
            <owl-carousel carouselClasses="owl-simple carousel-with-shadow" [options]="sliderOption">
                <molla-product-twelve [product]="product" *ngFor="let product of products | slice: 3 : 7"></molla-product-twelve>
            </owl-carousel>
        </ng-template>
        
    </div>
    <div class="mb-lg-5"></div>
</div>