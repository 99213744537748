<!-- {{userAddressList | json}} -->
<form [formGroup]="contactForm" (ngSubmit)="onSubmit()" *ngIf="addressEnable">
  <div class="row">
    <div class="col-lg-9">
      <h2 class="checkout-title">Billing Details</h2>
      <div class="row">
        <label>Company Name (Optional)</label>
        <input type="text" class="form-control" name="company" formControlName="company">

        <label>Country *</label>
        <input type="text" class="form-control" name="country" formControlName="country" required
          [ngClass]="{ 'is-invalid': submitted && fL['country'].errors }">
        <div *ngIf="submitted && fL['country'].errors" class="invalid-feedback">
          <div *ngIf="fL['country'].errors['required']">
            This field is required</div>
        </div>
        <label>Street address *</label>
        <input type="text" class="form-control" name="street1" formControlName="street1"
          placeholder="House number and Street name" required
          [ngClass]="{ 'is-invalid': submitted && fL['street1'].errors }">
        <div *ngIf="submitted && fL['street1'].errors" class="invalid-feedback">
          <div *ngIf="fL['street1'].errors['required']">
            This field is required</div>
        </div>
        <input type="text" class="form-control" name="street2" formControlName="street2"
          placeholder="Appartments, suite, unit etc ...">
        <div class="row">
          <div class="col-sm-6">
            <label>Town / City *</label>
            <input type="text" name="city" formControlName="city" class="form-control" required
              [ngClass]="{ 'is-invalid': submitted && fL['city'].errors }">
            <div *ngIf="submitted && fL['city'].errors" class="invalid-feedback">
              <div *ngIf="fL['city'].errors['required']">
                This field is required</div>
            </div>
          </div>

          <div class="col-sm-6">
            <label>Province *</label>
            <input type="text" name="state" formControlName="state" class="form-control" required
              [ngClass]="{ 'is-invalid': submitted && fL['state'].errors }">
            <div *ngIf="submitted && fL['state'].errors" class="invalid-feedback">
              <div *ngIf="fL['state'].errors['required']">
                This field is required</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <label>Postcode / ZIP *</label>
            <input type="text" name="postcode" formControlName="postcode" class="form-control" required
              [ngClass]="{ 'is-invalid': submitted && fL['postcode'].errors }">
            <div *ngIf="submitted && fL['postcode'].errors" class="invalid-feedback">
              <div *ngIf="fL['postcode'].errors['required']">
                This field is required</div>
            </div>
          </div>

          <div class="col-sm-6">
            <label>Phone *</label>
            <input type="tel" name="tel" formControlName="tel" class="form-control" required
              [ngClass]="{ 'is-invalid': submitted && fL['tel'].errors }">
            <div *ngIf="submitted && fL['tel'].errors" class="invalid-feedback">
              <div *ngIf="fL['tel'].errors['required']">
                This field is required</div>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-outline-primary-2 btn-order btn-block">
          <span class="btn-text">Save</span>
          <span class="btn-hover-text">Save</span>
        </button>
      </div>
    </div>
  </div>
</form>
<p class="text-primary"><b>The following addresses will be used on the checkout page by default.</b></p>

<div class="row">
  <div class="col-lg-6" *ngFor="let item of userAddressList">
    <div class="card card-dashboard">
      <div class="card-body">
        <h3 class="card-title">Billing Address</h3>

        <p>
          {{item.town}}<br>
          {{item.county}}<br>
          {{item.postCode}}<br>
          {{item.addressLine1}}<br>
          {{item.addressLine2}}<br>
          {{item.phoneNo}}<br>
          <a (click)="editAddress(item)" class="btn btn-primary text-white">Edit <i class="icon-edit"></i></a>
        </p>
      </div>
    </div>
  </div>

  <div class="col-lg-6" *ngIf="userAddressList.length == 0">
    <div class="card card-dashboard">
      <div class="card-body">
        <h3 class="card-title">Shipping Address</h3>

        <p>You have not set up this type of address yet.<br>
          <!-- <a href="javascript:;">Edit <i class="icon-edit"></i></a> -->
        </p>
      </div>
    </div>
  </div>
</div>
