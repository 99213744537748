<div class="main mt-4">
  <nav class="breadcrumb-nav border-0 mb-0">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/">Home</a>
        </li>
        <li class="breadcrumb-item">
          <a href="javascript:;">Track Parcel</a>
        </li>
        <!-- <li class="breadcrumb-item active">About Us</li> -->
      </ol>
    </div>
  </nav>

  <!-- <div class="container">
        <div class="page-header page-header-big text-center"
            style="background-image: url('assets/images/about-header-bg.jpg')">
            <h1 class="page-title text-white">About us<span class="text-white">Who we are</span></h1>
        </div>
    </div> -->

  <div class="page-content pb-0">
    <div class="container">
      <div class="row">
        <div class="col-1"></div>
        <div class="col-lg-8">
          <div class="search-product ">
            <input type="text" class="form-control" [(ngModel)]="orderName" placeholder="Enter Order Id" />
            <i class="fa-regular fa-xmark" (click)="close()"></i>
          </div>
        </div>
        <div class="col-lg-2">
          <button (click)="search()" type="button"
            class="btn btn-primary  text-uppercase text-dark text-decoration-none">
            Search
          </button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-hover child-table">
          <thead>
            <tr>
              <th>Category Name</th>
              <th>Product Name</th>
              <th>Modal Name</th>
              <th>Qty</th>
              <th>Amount</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let detail of productList">
              <td>{{ detail.categoryName }}</td>
              <td>{{ detail.itemName }}</td>
              <td>{{ detail.itemModalName }}</td>
              <td>{{ detail.qty }}</td>
              <td>{{ detail.amount }}</td>
              <td>{{ detail.status ? detail.status : 'Pending' }}</td>
              <td><img [src]="imagePath +'/' + detail.imagePath" style="max-width: 100px;"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
