import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { categorySlider } from '../data';

@Component({
  selector: 'molla-top-category',
  templateUrl: './top-category.component.html',
  styleUrls: ['./top-category.component.scss']
})
export class TopCategoryComponent implements OnInit {

  categorySlider = categorySlider;
  @Input() topCategory = [];
  @Input() topSubCategory = [];
  @Input() catgloaded = false;
  SERVER_URL = environment.serverApiUrl;
	subCategoryImgPath =environment.subCategoryImgPath;
  constructor() { }

  ngOnInit(): void {
  }

}
