import { Component, OnInit, Input } from '@angular/core';

import { tabSlider } from '../data';

@Component({
  selector: 'molla-fashion-collection',
  templateUrl: './fashion-collection.component.html',
  styleUrls: ['./fashion-collection.component.scss']
})
export class FashionCollectionComponent implements OnInit {

  @Input() products = [];
	@Input() loaded = false;
  @Input() otherproducts = [];
  sliderOption = tabSlider;

  constructor() { }

  ngOnInit(): void {
  }

}
