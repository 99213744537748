<div class="product d-flex flex-column overflow-hidden" [ngClass]="addClass">
    <figure class="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
        <!-- <span class="product-label label-new" *ngIf="product.new">New</span>
        <span class="product-label label-sale" *ngIf="product.sale_price">Sale</span>
        <span class="product-label label-top" *ngIf="product.top">Top</span> -->
        <!-- <span class="product-label label-out" *ngIf="!product.stock || product.stock == 0">Out Of Stock</span> -->
        <a [routerLink]="['/product/default/'+ product.id]">
            <molla-image src='{{productImgPath}}/{{product.imagePath}}' alt="Product"></molla-image>

            <!-- <molla-image [src]="SERVER_URL + product.sm_pictures[0].url" alt="Product" class="product-image">
            </molla-image>
            <molla-image [src]="SERVER_URL + product.sm_pictures[1].url" alt="Product" class="product-image-hover"
                *ngIf="product.sm_pictures[1]"></molla-image> -->
        </a>

        <molla-count-down [until]="product.until" format="DHMS" *ngIf="product.until" wrap="product-countdown bg-light">
        </molla-count-down>

        <div class="product-action-vertical">
            <a href="javascript:;" class="btn-product-icon btn-wishlist text-dark"
                [class.added-to-wishlist]="isInWishlist()" (click)="addToWishlist($event)">
                <span>{{ isInWishlist() ? 'go' : 'add' }} to Wishlist</span>
            </a>
            <!-- <a href="javascript:;" class="btn-product-icon btn-quickview text-dark" title="Quick view"
                (click)="quickView($event)"><span>Quick view</span></a> -->
                <a href="javascript:;" class="btn-product-icon btn-quickview text-dark" title="Quick view"
                (click)="quickView($event,product.id)"><span>Quick view</span></a>
            <!-- <a href="javascript:;" class="btn-product-icon btn-compare text-dark" title="Compare"
                (click)="addToCompare($event)"><span>Compare</span></a> -->
        </div>
    </figure>

    <div class="product-body pb-3">
        <div class="text-left product-cat font-weight-normal text-light mb-0">
            <span >
                <a [routerLink]="['/shop/sidebar/4cols']">{{ product.categoryName }} <span *ngIf="product.sizeName"> - {{product.sizeName}}</span></a>
            </span>
        </div>

        <h3 class="product-title letter-spacing-normal font-size-normal text-left mb-0">
            <a [routerLink]="['/product/default/'+ product.id]">{{ product.itemName }}</a>
        </h3>

        <!-- <div class="product-price mb-1" *ngIf="!product.stock || product.stock == 0 else elseBlock">
            <span class="out-price">${{ product.price }}</span>
        </div> -->

        <div class="product-price mb-1">
            <span class="out-price">{{currencyText}}{{ product.salePricePerItem }}</span>
        </div>
        <div >
          <b>Product Code: {{product.id}}</b>
        </div>
        <div *ngIf="product.description">
          {{product.description | slice : 0 : 50}}
        </div>
        <!-- <ng-template #elseBlock>
            <div class="product-price mb-1" *ngIf="minPrice == maxPrice else elseBlock">{{currencyText}}{{ minPrice }}</div>
            <ng-template #elseBlock>
                <div class="product-price mb-1" *ngIf="product.variants.length == 0 else elseBlock">
                    <span class="new-price">{{currencyText}}{{ minPrice }}</span>
                    <span class="old-price">{{currencyText}}{{ maxPrice }}</span>
                </div>
                <ng-template #elseBlock>
                    <div class="product-price mb-1">{{currencyText}}{{minPrice}}&ndash;{{currencyText}}{{maxPrice}}</div>
                </ng-template>
            </ng-template>
        </ng-template> -->

        <!-- <div class="ratings-container">
            <div class="ratings">
                <div class="ratings-val" [style.width]="product.ratings * 20 + '%'"></div>
                <span class="tooltip-text">{{ product.ratings }}</span>
            </div>
            <span class="ratings-text">( {{ product.review }} Reviews )</span>
        </div> -->

        <!-- <div class="product-nav product-nav-dots" *ngIf="product.variants.length > 0">
            <div class="row no-gutters">
                <a href="javascript:;" [style.backgroundColor]="item.color" *ngFor="let item of product.variants">
                    <span class="sr-only">Color name</span></a>
            </div>
        </div> -->
    </div>
    <div class="product-action position-relative visible">
        <!-- <a [routerLink]="['/product/default/'+ product.id]" class="btn-product btn-cart text-uppercase text-dark text-decoration-none btn-select"
            *ngIf="product.variants.length > 0 else cartBlock">
            <span>select options</span>
        </a> -->
        <!-- <ng-template #cartBlock> -->
            <button class="btn-product btn-cart text-uppercase text-dark text-decoration-none" (click)="addToCart($event)">
                <span class="text-dark border-0">add to cart</span>
            </button>
        <!-- </ng-template> -->
    </div>

    <!-- <div class="product-sold" *ngIf="isShownSold">
        <div class="sold position-relative w-100">
            <div class="sold-val position-absolute" [style.width]="( product.sold / ( product.sold + product.stock ) ) * 100 + '%'"></div>
            <span class="sold-text font-size-normal second-primary-color">Sold: {{ product.sold }}</span>
        </div>
    </div> -->
</div>
