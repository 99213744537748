import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

import { CartService } from 'src/app/shared/services/cart.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { environment } from 'src/environments/environment.prod';

@Component({
	selector: 'molla-cart-menu',
	templateUrl: './cart-menu.component.html',
	styleUrls: ['./cart-menu.component.scss']
})

export class CartMenuComponent implements OnInit {

	SERVER_URL = environment.productImgPath;
	currencyText = environment.currencyText;
  userCheck: any;
	constructor(public cartService: CartService,private auth: AuthenticationService,
    private modelService:ModalService,private router: Router) {
    this.auth.currentUser.subscribe(res => {
      this.userCheck = res
    })
	}

	ngOnInit(): void {
	}

	removeFromCart(event: Event, product: any) {
		event.preventDefault();
		this.cartService.removeFromCart(product);
	}
  check(){
    if(!this.userCheck){
      this.modelService.showLoginModal();
    }else{
      this.router.navigate(['/shop/checkout'])
    }
  }
}
