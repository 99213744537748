import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter, first } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Store } from '@ngrx/store';

import { StoreService } from './core/store/store.service';
import { UtilsService } from './shared/services/utils.service';

import { RefreshStoreAction } from 'src/app/core/actions/actions';
import { environment } from 'src/environments/environment';
import { Lightbox } from 'ngx-lightbox';
// import { NgxUiLoaderConfig } from 'ngx-ui-loader';

declare var $: any;

@Component({
	selector: 'molla-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent {
//   config : NgxUiLoaderConfig;
	constructor(
		public store: Store<any>,
		public router: Router,
		public viewPort: ViewportScroller,
		public storeService: StoreService,
		public utilsService: UtilsService,
		public modalService: NgbModal ,
    private _lightbox: Lightbox
	) {
		const navigationEnd = this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		);

		navigationEnd.pipe(first()).subscribe(() => {
			document.querySelector('body')?.classList.add('loaded');
			var timer = setInterval(() => {
				if( window.getComputedStyle( document.querySelector('body') ).visibility == 'visible') {
					clearInterval(timer);
					$('.owl-carousel').trigger('refresh.owl.carousel');
				}
			}, 300);
		});

		navigationEnd.subscribe((event: any) => {
			if (!event.url.includes('/shop/sidebar') && !event.url.includes('/shop/nosidebar') && !event.url.includes('/shop/market') && !event.url.includes('/blog')) {
				this.viewPort.scrollToPosition([0, 0]);
			}

			this.modalService.dismissAll();
		})

		if (localStorage.getItem("molla-angular-demo") !== environment.demo) {
			this.store.dispatch(new RefreshStoreAction());
		}

		localStorage.setItem("molla-angular-demo", environment.demo);
	}

	@HostListener('window: scroll', ['$event'])
	onWindowScroll(e: Event) {
		this.utilsService.setStickyHeader();
	}
  ngOnInit(): void {
    // this.config={
    //   "bgsColor": "#dc3545",
    //   "bgsOpacity": 0.5,
    //   "bgsPosition": "bottom-right",
    //   "bgsSize": 60,
    //   "bgsType": "ball-spin-clockwise",
    //   "blur": 5,
    //   "delay": 0,
    //   "fastFadeOut": true,
    //   "fgsColor": "#dc3545",
    //   "fgsPosition": "center-center",
    //   "fgsSize": 60,
    //   "fgsType": "ball-spin-clockwise",
    //   "gap": 24,
    //   "logoPosition": "center-center",
    //   "logoSize": 120,
    //   "logoUrl": "",
    //   "masterLoaderId": "master",
    //   "overlayBorderRadius": "0",
    //   "overlayColor": "rgba(40, 40, 40, 0.8)",
    //   "pbColor": "#c39c52",
    //   "pbDirection": "ltr",
    //   "pbThickness": 3,
    //   "hasProgressBar": true,
    //   "text": "Pleas wait...",
    //   "textColor": "#FFFFFF",
    //   "textPosition": "center-center",
    //   "maxTime": -1,
    //   "minTime": 300
    // };
  }
	hideMobileMenu() {
		document.querySelector('body').classList.remove('mmenu-active');
		document.querySelector('html').style.overflowX = 'unset';
	}
}
