<div class="container deal-section">
    <h3 class="title text-center mt-5 font-weight-bold" contentAnim aniName="blurIn">Today's Best Deal</h3>
    <owl-carousel carouselClasses="deal-carousel owl-simple carousel-equal-height" [options]="sliderOption" *ngIf="!loaded else elseBlock">
        <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
    </owl-carousel>
    <ng-template #elseBlock>
        <owl-carousel carouselClasses="deal-carousel owl-simple carousel-equal-height" [options]="sliderOption">
            <molla-product-twelve *ngFor="let product of products" [product]="product" [isShownSold]="true"></molla-product-twelve>
        </owl-carousel>
    </ng-template>
</div>