import { Component, Input, OnInit } from '@angular/core';
import { tabSlider } from '../data';

@Component({
  selector: 'molla-electric-collention',
  templateUrl: './electric-collention.component.html',
  styleUrls: ['./electric-collention.component.scss']
})
export class ElectricCollentionComponent implements OnInit {
  @Input() otherproducts = [];
  @Input() products = [];
	@Input() loaded = false;
  sliderOption = tabSlider;
  constructor() { }

  ngOnInit(): void {
  }

}
