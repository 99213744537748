import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { CategoryService } from 'src/app/shared/services/category.service';

@Component({
  selector: 'molla-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {
  electric = '';
  electronic = '';
  garments = '';
  fabric = '';
  general = '';
  current = '/';
  categoryList = [];
  ElectronicCategoryList = [];
  ElectronicCategory = [];
  electricCategoryList = [];
  electricCategory = [];
  garmentsCategoryList = [];
  garmentsCategory = [];
  fabricCategory = [];
  fabricCategoryList = [];
  generalCategory = [];
  generalCategoryList = [];
  private subscr: Subscription;

  constructor(private router: Router, private categoryService: CategoryService) {
    this.subscr = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.current = event.url;
      } else if (event instanceof NavigationEnd) {
        this.current = event.url;
      }
    });
    this.categoryService.getAllCategory().subscribe(res => {

      if (res.successFlag) {
        this.categoryList = [];
        this.categoryList.splice(0, this.categoryList.length);
        this.categoryList.push(...res.data);
        this.electricCategoryList = this.categoryList.filter(a => a.companyId == 6);
        this.electricCategory = this.categoryList.filter(a => a.companyId == 6);
        this.ElectronicCategoryList = this.categoryList.filter(a => a.companyId == 8);
        this.ElectronicCategory = this.categoryList.filter(a => a.companyId == 8);
        this.garmentsCategoryList = this.categoryList.filter(a => a.companyId == 7);
        this.garmentsCategory = this.categoryList.filter(a => a.companyId == 7);
        this.fabricCategoryList = this.categoryList.filter(a => a.companyId == 10002);
        this.fabricCategory = this.categoryList.filter(a => a.companyId == 10002);
        this.generalCategoryList = this.categoryList.filter(a => a.companyId == 20002);
        this.generalCategory = this.categoryList.filter(a => a.companyId == 20002);
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscr.unsubscribe();
  }

  viewAllDemos(event: any) {
    event.preventDefault();
    var list = document.querySelectorAll('.demo-list .hidden');
    for (let i = 0; i < list.length; i++) {
      list[i].classList.add('show');
    }

    event.target.parentElement.classList.add('d-none');
  }
  changeElectricCategory() {
    if (this.electric)
      this.electricCategoryList = this.electricCategory.filter(a => a.categoryName.toLowerCase().includes(this.electric.toLowerCase()));
    else
      this.electricCategoryList = this.electricCategory;

  }
  assignAllElectric() {
    this.electricCategoryList = this.electricCategory;
  }
  changeElectronicCategory() {
    if (this.electronic)
      this.ElectronicCategoryList = this.ElectronicCategory.filter(a => a.categoryName.toLowerCase().includes(this.electronic.toLowerCase()));
    else
      this.ElectronicCategoryList = this.ElectronicCategory;

  }
  assignAllElectronic() {
    this.ElectronicCategoryList = this.ElectronicCategory;
  }
  changeGarmentCategory() {
    if (this.garments)
      this.garmentsCategoryList = this.garmentsCategory.filter(a => a.categoryName.toLowerCase().includes(this.garments.toLowerCase()));
    else
      this.garmentsCategoryList = this.garmentsCategory;

  }
  assignAllGarment() {
    this.garmentsCategoryList = this.garmentsCategory;
  }
  changeFabricCategory() {
    if (this.fabric)
      this.fabricCategoryList = this.fabricCategory.filter(a => a.categoryName.toLowerCase().includes(this.fabric.toLowerCase()));
    else
      this.fabricCategoryList = this.fabricCategory;

  }
  assignAllfabric() {
    this.fabricCategoryList = this.fabricCategory;
  }
  changeGeneralCategory() {
    if (this.general)
      this.generalCategoryList = this.generalCategory.filter(a => a.categoryName.toLowerCase().includes(this.general.toLowerCase()));
    else
      this.generalCategoryList = this.generalCategory;

  }
  assignAllgeneral() {
    this.generalCategoryList = this.generalCategory;
  }
}
