import { Injectable } from '@angular/core';
import { BaseApiService } from "./base-api.service";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from "./authentication.service";
import { Observable, throwError } from "rxjs";
import { APIResponse } from "../models/a-p-i-Response";
import { environment } from 'src/environments/environment.prod';
import { Orderlist } from '../models/dtos/OrderList';
//import { ToastrService } from 'ngx-toastr/toastr/to

@Injectable({
  providedIn: 'root'
})
export class SaleService extends BaseApiService<Orderlist>{
  commonEndPoint = 'Sale';

  constructor(private _httpClient: HttpClient, public _authenticationService: AuthenticationService) {
    super(_httpClient, _authenticationService);

  }

  // custome Apis 
  getOrderByUser(userId: number) {
    return this.http.get<APIResponse<Orderlist[]>>(this.baseUrl + this.commonEndPoint + "/GetOrderByUser?userId=" + userId, {
      headers: this.headers
    });
  }
}
