import { ApiService } from 'src/app/shared/services/api.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'molla-user-shiping-addresses',
  templateUrl: './user-shiping-addresses.component.html',
  styleUrls: ['./user-shiping-addresses.component.scss']
})
export class UserShipingAddressesComponent implements OnInit {
  userAddressList = [];
  contactForm: FormGroup;
  userCheck: any;
  submitted = false;
  addressEnable = false;
  constructor(private apiService: ApiService, private auth: AuthenticationService,
    private _toastrService: ToastrService,
    private formBuilder: FormBuilder) {
    this.auth.currentUser.subscribe(res => {
      this.userCheck = res
    })
    this.createContactForm();
  }

  ngOnInit(): void {

    this.getAddress();
  }
  getAddress() {
    this.apiService.getAddress(this.userCheck.id).subscribe(res => {
      if (res.successFlag) {
        this.userAddressList = res.data;
      } else {
        this.userAddressList = []
      }
    })
  }
  createContactForm() {
    this.contactForm = this.formBuilder.group({
      addressId: ["", [Validators.required]],
      company: [''],
      country: ['', [Validators.required]],
      street1: ['', [Validators.required]],
      street2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      postcode: ['', [Validators.required]],
      tel: [''],
    });
    this.contactForm.controls['country'].disable();
    this.contactForm.controls['country'].setValue("Pakistan");
  }
  get fL() { return this.contactForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.contactForm.valid) {
      this.apiService.saveAddress(this.contactForm.value).subscribe(res => {
        if (res.successFlag) {
          this.contactForm.reset();
          this.addressEnable = false;
          this.getAddress();
          this._toastrService.success(res.message,
            "Success",
            { toastClass: "toast ngx-toastr", closeButton: true }
          );
        } else {
          this._toastrService.error(res.data.message,
            "Error",
            { toastClass: "toast ngx-toastr", closeButton: true }
          );
        }
      })
    }
  }
  editAddress(data: any) {

    this.addressEnable = true;
    let obj = {
      addressId: data.id,
      company: data.organizationName,
      country: data.county,
      street1: data.addressLine1,
      street2: data.addressLine2,
      city: data.town,
      state: data.town,
      postcode: data.postCode,
      tel: data.phoneNo,
      email: data.email,
    }
    this.contactForm.patchValue(obj);
  }
}
