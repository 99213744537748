import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { customSay, team, brands } from './about-one-data';
import { sliderOpt } from 'src/app/shared/data';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment.prod';
import { CommonService } from 'src/app/shared/services/common.service';

@Component( {
	selector: 'pages-about-page',
	templateUrl: './about-one.component.html',
	styleUrls: [ './about-one.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush
} )

export class AboutOneComponent implements OnInit {
  orderName = "";
	customSay = customSay;
  productList: any[] = [];
  imagePath = environment.productImgPath
	brands = brands;
	team = team;
	sliderOption = {
		...sliderOpt,
		nav: false,
		dots: true,
		margin: 20,
		loop: false,
		responsive: {
			1200: {
				nav: true
			}
		}
	}
	teamOption = {
		...sliderOpt,
		nav: false,
		dots: false,
		items: 3,
		margin: 20,
		loop: false,
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			992: {
				items: 3
			}
		}
	}

	constructor (public productService: ProductService,  private commonService:CommonService,
    private cd:ChangeDetectorRef) {
	}

	ngOnInit (): void {
	}
  close(){
    this.orderName = "";
  }
  search(){
    this.commonService.startLoader();
    this.productService.getOrderDetails(this.orderName).subscribe(
      (response) => {
        this.commonService.stopLoader();
        if(response.successFlag)
        this.productList = response.data;
        this.cd.detectChanges();
      },
      (error) => {
        this.commonService.stopLoader();
        console.error('Error fetching product:', error);
      }
    );
  }
}
