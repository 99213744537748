<nav class="side-nav">
    <div class="sidenav-title letter-spacing-normal font-size-normal d-flex justify-content-xl-between align-items-center bg-primary justify-content-center text-truncate">Browse Categories
        <i class="icon-bars float-right h5 text-white m-0 d-none d-xl-block"></i>
    </div>

    <ul class="menu-vertical sf-arrows sf-js-enabled" style="touch-action: pan-y;">
        <!-- <li class="megamenu-container">
            <a class="sf-with-ul text-dark" routerLink="/shop/sidebar/4cols" [queryParams]="{category: 'electronics'}">
                <i class="icon-laptop"></i>ADF Premium Dry Fruits</a>
            <div class="megamenu">
                <div class="row ">
                    <div class="col-md-8">
                        <div class="menu-col">
                            <div class="row"> -->
                                <!-- <div class="col-md-6">
                                    <div class="menu-title">ADF Premium Dry Fruits</div>
                                    <ul>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Hazelnuts</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Almond</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Dates</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Raisins</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Cashew</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Apricot</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Walnuts</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Plums</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Pistachio</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Injeer</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Rango o Riwayat</a>
                                        </li>

                                    </ul>

                                </div> -->


                                <!-- <div class="col-md-6">
                                    <div class="menu-title">Cell Phones</div>

                                    <ul>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Carrier Phones</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Unlocked Phones</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Phone &amp; Cellphone Cases</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Cellphone Chargers </a>
                                        </li>
                                    </ul>

                                    <div class="menu-title">Digital Cameras</div>

                                    <ul>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Digital SLR Cameras</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Sports &amp; Action Cameras</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Camcorders</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Camera Lenses</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Photo Printer</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Digital Memory Cards</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Camera Bags, Backpacks &amp; Cases</a>
                                        </li>
                                    </ul>
                                </div> -->
<!--
                            </div>

                        </div>

                    </div>


                    <div class="col-md-4">
                        <div class="banner banner-overlay h-100">
                            <a routerLink="/shop/sidebar/4cols" class="banner banner-menu">
                                <img src="assets/images/home/menu/banner-1.jpg" alt="Banner" width="280" height="440">
                            </a>
                        </div>

                    </div>

                </div>

            </div>

        </li> -->
        <!-- <li class="megamenu-container">
            <a class="sf-with-ul text-dark" routerLink="/shop/sidebar/4cols" [queryParams]="{category: 'furniture'}">
                <i class="icon-couch"></i>Furniture</a>

            <div class="megamenu">
                <div class="row ">
                    <div class="col-md-8">
                        <div class="menu-col">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="menu-title">Bedroom</div>

                                    <ul>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Beds, Frames &amp; Bases</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Dressers</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Nightstands</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Kids' Beds &amp; Headboards</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Armoires</a>
                                        </li>
                                    </ul>

                                    <div class="menu-title">Living Room</div>

                                    <ul>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Coffee Tables</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Chairs</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Tables</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Futons &amp; Sofa Beds</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Cabinets &amp; Chests</a>
                                        </li>
                                    </ul>
                                </div>


                                <div class="col-md-6">
                                    <div class="menu-title">Office</div>

                                    <ul>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Office Chairs</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Desks</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Bookcases</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">File Cabinets</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Breakroom Tables</a>
                                        </li>
                                    </ul>

                                    <div class="menu-title">Kitchen &amp; Dining</div>

                                    <ul>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Dining Sets</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Kitchen Storage Cabinets</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Bakers Racks</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Dining Chairs</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Dining Room Tables</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Bar Stools</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>

                    </div>


                    <div class="col-md-4">
                        <div class="banner banner-overlay h-100">
                            <a routerLink="/shop/sidebar/4cols" class="banner banner-menu">
                                <img src="assets/images/home/menu/banner-2.jpg" alt="Banner" width="280" height="440">
                            </a>
                        </div>

                    </div>

                </div>

            </div>

        </li> -->
        <!-- <li class="megamenu-container">
            <a class="sf-with-ul text-dark" routerLink="/shop/sidebar/4cols" [queryParams]="{category: 'cooking'}">
                <i class="icon-concierge-bell"></i>Cooking</a>

            <div class="megamenu">
                <div class="menu-col">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="menu-title">Cookware</div>

                            <ul>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Cookware Sets</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Pans, Griddles &amp;Woks</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Pots</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Skillets &amp;Grill Pans</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Kettles</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Soup &amp;Stockpots</a>
                                </li>
                            </ul>
                        </div>


                        <div class="col-md-4">
                            <div class="menu-title">Dinnerware &amp;Tabletop</div>

                            <ul>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Plates</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Cups &amp;Mugs</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Trays &amp;Platters</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Coffee &amp;Tea Serving</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Salt &amp;Pepper Shaker</a>
                                </li>
                            </ul>
                        </div>


                        <div class="col-md-4">
                            <div class="menu-title">Cooking Appliances</div>

                            <ul>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Microwaves</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Coffee Makers</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Mixers &amp;Attachments</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Slow Cookers</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Air Fryers</a>
                                </li>
                                <li>
                                    <a routerLink="/shop/sidebar/4cols">Toasters &amp;Ovens</a>
                                </li>
                            </ul>
                        </div>

                    </div>


                    <div class="row menu-banners">
                        <div class="col-md-4">
                            <div class="banner h-100">
                                <a routerLink="/shop/sidebar/4cols">
                                    <img src="assets/images/home/menu/1.jpg" alt="image" width="251" height="141">
                                </a>
                            </div>

                        </div>


                        <div class="col-md-4">
                            <div class="banner h-100">
                                <a routerLink="/shop/sidebar/4cols">
                                    <img src="assets/images/home/menu/2.jpg" alt="image" width="251" height="141">
                                </a>
                            </div>

                        </div>


                        <div class="col-md-4 h-100">
                            <div class="banner">
                                <a routerLink="/shop/sidebar/4cols">
                                    <img src="assets/images/home/menu/3.jpg" alt="image" width="251" height="141">
                                </a>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </li> -->
        <!-- <li class="megamenu-container">
            <a class="sf-with-ul text-dark" routerLink="/shop/sidebar/4cols" [queryParams]="{category: 'clothing'}">
                <i class="icon-tshirt"></i>Clothing</a>

            <div class="megamenu">
                <div class="row ">
                    <div class="col-md-8">
                        <div class="menu-col">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="menu-title">Women</div>

                                    <ul>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">
                                                <strong>New Arrivals</strong>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">
                                                <strong>Best Sellers</strong>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">
                                                <strong>Trending</strong>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Clothing</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Shoes</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Bags</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Accessories</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Jewlery &amp;Watches</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">
                                                <strong>Sale</strong>
                                            </a>
                                        </li>
                                    </ul>
                                </div>


                                <div class="col-md-6">
                                    <div class="menu-title">Men</div>

                                    <ul>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">
                                                <strong>New Arrivals</strong>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">
                                                <strong>Best Sellers</strong>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">
                                                <strong>Trending</strong>
                                            </a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Clothing</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Shoes</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Bags</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Accessories</a>
                                        </li>
                                        <li>
                                            <a routerLink="/shop/sidebar/4cols">Jewlery &amp;Watches</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>

                    </div>


                    <div class="col-md-4">
                        <div class="banner banner-overlay">
                            <a routerLink="/shop/sidebar/4cols" class="banner banner-menu">
                                <img src="assets/images/home/menu/banner-3.jpg" alt="Banner" width="280" height="347">
                            </a>
                        </div>

                    </div>

                </div>


                <div class="menu-col menu-brands d-flex flex-column justify-content-center">
                    <div class="row">
                        <div class="col-lg-2">
                            <a href="#" class="brand h-100">
                                <img src="assets/images/brands/1.png" alt="Brand Name" width="100" height="23">
                            </a>
                        </div>


                        <div class="col-lg-2">
                            <a href="#" class="brand h-100">
                                <img src="assets/images/brands/2.png" alt="Brand Name" width="101" height="34">
                            </a>
                        </div>


                        <div class="col-lg-2">
                            <a href="#" class="brand h-100">
                                <img src="assets/images/brands/3.png" alt="Brand Name" width="100" height="30">
                            </a>
                        </div>


                        <div class="col-lg-2">
                            <a href="#" class="brand h-100">
                                <img src="assets/images/brands/4.png" alt="Brand Name" width="101" height="39">
                            </a>
                        </div>


                        <div class="col-lg-2">
                            <a href="#" class="brand h-100">
                                <img src="assets/images/brands/5.png" alt="Brand Name" width="100" height="48">
                            </a>
                        </div>


                        <div class="col-lg-2">
                            <a href="#" class="brand h-100">
                                <img src="assets/images/brands/6.png" alt="Brand Name" width="100" height="23">
                            </a>
                        </div>

                    </div>

                </div>

            </div>

        </li> -->
        <!-- // Fazzi bind code -->
        <li *ngFor="let item of categoryList">
            <a routerLink="/shop/sidebar/4cols" class="text-dark" [queryParams]="{category: item.id}">
                <i class="icon-bars"></i>{{item.categoryName}}</a>
        </li>
        <li>
            <a class="text-dark" routerLink="/shop/category"><i class="icon-bars"></i>See More</a>
        </li>
    </ul>
</nav>
