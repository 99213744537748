<style>
  .menu {
    display: inline-block;
  }

  .menu li {
    display: inline-block;
  }
</style>
<nav class="main-nav">
  <ul class="menu">
    <!-- <li class="megamenu-container" [class.active]="current=='/'">
            <a routerLink="/" class="sf-with-ul"></a>
            <ul style="margin-top: -20px;" >
                <li *ngFor="let item of garmentsCategoryList">
                    <a routerLink="/shop/sidebar/4cols" class="sf-with-ul">{{item.categoryName}}</a>
                </li>
            </ul>
        </li> -->

    <li [class.active]="current.includes('/garments')">
      <a routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 7}" class="sf-with-ul">Garments</a>
      <ul style="margin-top: -20px;">
        <li class="drop-search">
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="garments" (input)="changeGarmentCategory()" placeholder="Search Product">
            <div class="input-group-prepend">
              <div class="input-group-text" (click)="assignAllGarment()">x</div>
            </div>
          </div>
        </li>
        <li *ngFor="let item of garmentsCategoryList" routerLink="/shop/sidebar/4cols"
          [queryParams]="{category: item.id}">
          <a class="sf-with-ul">{{item.categoryName}}</a>
        </li>
      </ul>
    </li>
    <li [class.active]="current.includes('/electric')">
      <a routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 6}" class="sf-with-ul">Electric</a>
      <ul style="margin-top: -20px;">
        <li class="drop-search">
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="electric" (input)="changeElectricCategory()" placeholder="Search Product">
            <div class="input-group-prepend">
              <div class="input-group-text" (click)="assignAllElectric()">x</div>
            </div>
          </div>
        </li>
        <li *ngFor="let item of electricCategoryList" routerLink="/shop/sidebar/4cols"
          [queryParams]="{category: item.id}">
          <a class="sf-with-ul">{{item.categoryName}}</a>
        </li>
      </ul>
    </li>

    <li [class.active]="current.includes('electronic')">
      <a routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 8}" class="sf-with-ul">Electronic</a>
      <ul style="margin-top: -20px;">
        <li class="drop-search">
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="electronic" (input)="changeElectronicCategory()" placeholder="Search Product">
            <div class="input-group-prepend">
              <div class="input-group-text" (click)="assignAllElectronic()">x</div>
            </div>
          </div>
        </li>
        <li *ngFor="let item of ElectronicCategoryList" routerLink="/shop/sidebar/4cols"
          [queryParams]="{category: item.id}">
          <a class="sf-with-ul">{{item.categoryName}}</a>
        </li>
      </ul>
    </li>
    <li>
      <a class="" routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 10002}">Fabric</a>
      <ul style="margin-top: -20px;">
        <li class="drop-search">
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="fabric" (input)="changeFabricCategory()"
              placeholder="Search Product">
            <div class="input-group-prepend">
              <div class="input-group-text" (click)="assignAllfabric()">x</div>
            </div>
          </div>
        </li>
        <li *ngFor="let item of fabricCategoryList | slice:0 : 15" routerLink="/shop/sidebar/4cols"
          [queryParams]="{category: item.id}">
          <a class="text-dark">{{item.categoryName}}</a>
        </li>
      </ul>
    </li>
    <li>
      <a class="" routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 20002}">General</a>
      <ul style="margin-top: -20px;">
        <li class="drop-search">
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="general" (input)="changeGeneralCategory()"
              placeholder="Search Product">
            <div class="input-group-prepend">
              <div class="input-group-text" (click)="assignAllgeneral()">x</div>
            </div>
          </div>
        </li>
        <li *ngFor="let item of generalCategoryList | slice:0 : 15" routerLink="/shop/sidebar/4cols"
          [queryParams]="{category: item.id}">
          <a class="text-dark">{{item.categoryName}}</a>
        </li>
      </ul>
    </li>
    <li [class.active]="current.includes('pages/about')">
      <a routerLink="/pages/about" class="sf-with-ul">Track Your Parcel</a>
    </li>
    <!-- class="menu sf-arrows" -->
    <!-- <li [class.active]="current.includes('/pages')">
            <a href="javascript:;" class="sf-with-ul">Dry Fruits</a>
            <ul>
                <li [class.active]="current.includes('pages/categories')">
                    <a routerLink="javascript:;" class="sf-with-ul">Hazelnuts 3</a>
                </li>
                <li [class.active]="current.includes('pages/categories')">
                    <a routerLink="javascript:;" class="sf-with-ul">Almond</a>
                </li>
                <li [class.active]="current.includes('pages/categories')">
                    <a routerLink="javascript:;" class="sf-with-ul">Dates</a>
                </li>
                <li [class.active]="current.includes('pages/categories')">
                    <a routerLink="javascript:;" class="sf-with-ul">Raisins</a>
                </li>
                <li [class.active]="current.includes('pages/categories')">
                    <a routerLink="javascript:;" class="sf-with-ul">Cashew</a>
                </li>
                <li [class.active]="current.includes('pages/categories')">
                    <a routerLink="javascript:;" class="sf-with-ul">Apricot</a>
                </li>
                <li [class.active]="current.includes('pages/categories')">
                    <a routerLink="javascript:;" class="sf-with-ul">Walnuts</a>
                </li>
                <li [class.active]="current.includes('pages/categories')">
                    <a routerLink="javascript:;" class="sf-with-ul">Plums</a>
                </li>
                <li [class.active]="current.includes('pages/categories')">
                    <a routerLink="javascript:;" class="sf-with-ul">Pistachio</a>
                </li>
                <li [class.active]="current.includes('pages/categories')">
                    <a routerLink="javascript:;" class="sf-with-ul">Injeer</a>
                </li>
                <li [class.active]="current.includes('pages/categories')">
                    <a routerLink="javascript:;" class="sf-with-ul">Candy & Choco</a>
                </li>
            </ul>
        </li> -->
    <!-- <li [class.active]="current.includes('pages/about')">
            <a routerLink="javascript:;" class="sf-with-ul">Food Point</a>
        </li> -->
    <!-- <li [class.active]="current.includes('pages/contact')">
            <a routerLink="/pages/contact" class="sf-with-ul">Contact Us</a>
        </li>         -->
    <!-- <div class="megamenu demo">
                <div class="menu-col">
                    <div class="menu-title">Choose your demo</div>

                    <div class="demo-list">
                        <div class="demo-item">
                            <a href="https://d-themes.com/angular/molla/demo1">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/1.jpg)"></span>
                                <span class="demo-title">01 - furniture store</span>
                            </a>
                        </div>

                        <div class="demo-item">
                            <a href="https://d-themes.com/angular/molla/demo2">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/2.jpg)"></span>
                                <span class="demo-title">02 - furniture store</span>
                            </a>
                        </div>

                        <div class="demo-item">
                            <a href="https://d-themes.com/angular/molla/demo3">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/3.jpg)"></span>
                                <span class="demo-title">03 - electronic store</span>
                            </a>
                        </div>

                        <div class="demo-item">
                            <a href="https://d-themes.com/angular/molla/demo4">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/4.jpg)"></span>
                                <span class="demo-title">04 - electronic store</span>
                            </a>
                        </div>

                        <div class="demo-item">
                            <a href="https://d-themes.com/angular/molla/demo5">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/5.jpg)"></span>
                                <span class="demo-title">05 - fashion store</span>
                            </a>
                        </div>

                        <div class="demo-item">
                            <a href="https://d-themes.com/angular/molla/demo6">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/6.jpg)"></span>
                                <span class="demo-title">06 - fashion store</span>
                            </a>
                        </div>

                        <div class="demo-item">
                            <a href="https://d-themes.com/angular/molla/demo7">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/7.jpg)"></span>
                                <span class="demo-title">07 - fashion store</span>
                            </a>
                        </div>

                        <div class="demo-item">
                            <a href="https://d-themes.com/angular/molla/demo8">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/8.jpg)"></span>
                                <span class="demo-title">08 - fashion store</span>
                            </a>
                        </div>

                        <div class="demo-item">
                            <a href="https://d-themes.com/angular/molla/demo9">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/9.jpg)"></span>
                                <span class="demo-title">09 - fashion store</span>
                            </a>
                        </div>

                        <div class="demo-item">
                            <a href="https://d-themes.com/angular/molla/demo10">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/10.jpg)"></span>
                                <span class="demo-title">10 - shoes store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo11">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/11.jpg)"></span>
                                <span class="demo-title">11 - furniture simple store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo12">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/12.jpg)"></span>
                                <span class="demo-title">12 - fashion simple store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo13">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/13.jpg)"></span>
                                <span class="demo-title">13 - market</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo14">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/14.jpg)"></span>
                                <span class="demo-title">14 - market fullwidth</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo15">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/15.jpg)"></span>
                                <span class="demo-title">15 - lookbook 1</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo16">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/16.jpg)"></span>
                                <span class="demo-title">16 - lookbook 2</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo17">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/17.jpg)"></span>
                                <span class="demo-title">17 - fashion store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo18">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/18.jpg)"></span>
                                <span class="demo-title">18 - fashion store (with sidebar)</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo19">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/19.jpg)"></span>
                                <span class="demo-title">19 - games store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo20">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/20.jpg)"></span>
                                <span class="demo-title">20 - book store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo21">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/21.jpg)"></span>
                                <span class="demo-title">21 - sport store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo22">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/22.jpg)"></span>
                                <span class="demo-title">22 - tools store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo23">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/23.jpg)"></span>
                                <span class="demo-title">23 - fashion left navigation store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo24">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/24.jpg)"></span>
                                <span class="demo-title">24 - extreme sport store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo25">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/25.jpg)"></span>
                                <span class="demo-title">25 - jewelry store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo26">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/26.jpg)"></span>
                                <span class="demo-title">26 - market store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo27">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/27.jpg)"></span>
                                <span class="demo-title">27 - fashion store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo28">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/28.jpg)"></span>
                                <span class="demo-title">28 - food market store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo29">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/29.jpg)"></span>
                                <span class="demo-title">29 - t-shirts store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo30">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/30.jpg)"></span>
                                <span class="demo-title">30 - headphones store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo31">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/31.jpg)"></span>
                                <span class="demo-title">31 - yoga store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo32">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/32.jpg)"></span>
                                <span class="demo-title">32 - sunglasses store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo33">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/33.jpg)"></span>
                                <span class="demo-title">33 - cosmetics store</span>
                            </a>
                        </div>

                        <div class="demo-item hidden">
                            <a href="https://d-themes.com/angular/molla/demo34">
                                <span class="demo-bg"
                                    style="background-image: url(assets/images/menu/demos/34.jpg)"></span>
                                <span class="demo-title">34 - car parts</span>
                            </a>
                        </div>
                    </div>

                    <div class="megamenu-action text-center">
                        <a href="#" class="btn btn-outline-primary-2 view-all-demos" (click)="viewAllDemos($event)">
                            <span>View All Demos</span>
                            <i class="icon-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </li>-->
    <!-- <li [class.active]="current.includes('/shop')">
            <a routerLink="/shop/sidebar/4cols" class="sf-with-ul">Shop</a>

            <div class="megamenu megamenu-md">
                <div class="row no-gutters">
                    <div class="col-md-8">
                        <div class="menu-col">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="menu-title">Shop with sidebar</div>

                                    <ul>
                                        <li [class.active]="current.includes('shop/sidebar/list')">
                                            <a routerLink="/shop/sidebar/4cols">Shop List</a>
                                        </li>
                                        <li [class.active]="current.includes('shop/sidebar/2cols')">
                                            <a routerLink="/shop/sidebar/2cols">Shop Grid 2 Columns</a>
                                        </li>
                                        <li [class.active]="current.includes('shop/sidebar/3cols')">
                                            <a routerLink="/shop/sidebar/3cols">Shop Grid 3 Columns</a>
                                        </li>
                                        <li [class.active]="current.includes('shop/sidebar/4cols')">
                                            <a routerLink="/shop/sidebar/4cols">Shop Grid 4 Columns</a>
                                        </li>
                                        <li [class.active]="current.includes('shop/market')">
                                            <a routerLink="/shop/market">
                                                <span>
                                                    Shop Market
                                                    <span class="tip tip-new">New</span>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>

                                    <div class="menu-title">Shop no sidebar</div>

                                    <ul>
                                        <li [class.active]="current.includes('shop/nosidebar/boxed')">
                                            <a routerLink="/shop/nosidebar/boxed">
                                                <span>
                                                    Shop Boxed No Sidebar
                                                    <span class="tip tip-hot">Hot</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li [class.active]="current.includes('shop/nosidebar/fullwidth')">
                                            <a routerLink="/shop/nosidebar/fullwidth">Shop Fullwidth No Sidebar</a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-md-6">
                                    <div class="menu-title">Product Category</div>

                                    <ul>
                                        <li [class.active]="current.includes('shop/category/boxed')">
                                            <a routerLink="/shop/category/boxed">Product Category Boxed</a>
                                        </li>
                                        <li [class.active]="current.includes('shop/category/fullwidth')">
                                            <a routerLink="/shop/category/fullwidth">
                                                <span>
                                                    Product Category Fullwidth
                                                    <span class="tip tip-new">New</span>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="menu-title">Shop Pages</div>

                                    <ul>
                                        <li [class.active]="current.includes('shop/cart')">
                                            <a routerLink="/shop/cart">Cart</a>
                                        </li>
                                        <li [class.active]="current.includes('shop/checkout')">
                                            <a routerLink="/shop/checkout">Checkout</a>
                                        </li>
                                        <li [class.active]="current.includes('shop/wishlist')">
                                            <a routerLink="/shop/wishlist">Wishlist</a>
                                        </li>
                                        <li [class.active]="current.includes('shop/dashboard')">
                                            <a routerLink="/shop/dashboard">My Account</a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">Lookbook</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="banner banner-overlay">
                            <a routerLink="/shop/sidebar/4cols" class="banner banner-menu">
                                <img src="assets/images/menu/banner-1.jpg" alt="Banner" width="218" height="314" />

                                <div class="banner-content banner-content-top">
                                    <div class="banner-title text-white">
                                        Last
                                        <br />Chance
                                        <br />
                                        <span>
                                            <strong>Sale</strong>
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li [class.active]="current.includes('/product/')">
            <a routerLink="/product/default/dark-yellow-lace-cut-out-swing-dress" class="sf-with-ul">Product</a>

            <div class="megamenu megamenu-sm">
                <div class="row no-gutters">
                    <div class="col-md-6">
                        <div class="menu-col">
                            <div class="menu-title">Product Details</div>

                            <ul>
                                <li [class.active]="current.includes('product/default')">
                                    <a routerLink="/product/default/dark-yellow-lace-cut-out-swing-dress">Default</a>
                                </li>
                                <li [class.active]="current.includes('product/centered')">
                                    <a
                                        routerLink="/product/centered/beige-ring-handle-circle-cross-body-bag">Centered</a>
                                </li>
                                <li [class.active]="current.includes('product/extended')">
                                    <a routerLink="/product/extended/yellow-tie-strap-block-heel-sandals">
                                        <span>
                                            Extended Info
                                            <span class="tip tip-new">New</span>
                                        </span>
                                    </a>
                                </li>
                                <li [class.active]="current.includes('product/gallery')">
                                    <a routerLink="/product/gallery/beige-metal-hoop-tote-bag">Gallery</a>
                                </li>
                                <li [class.active]="current.includes('product/sticky')">
                                    <a routerLink="/product/sticky/brown-faux-fur-longline-coat">Sticky Info</a>
                                </li>
                                <li [class.active]="current.includes('product/sidebar')">
                                    <a routerLink="/product/sidebar/beige-v-neck-button-cardigan">Boxed With Sidebar</a>
                                </li>
                                <li [class.active]="current.includes('product/fullwidth')">
                                    <a routerLink="/product/fullwidth/black-faux-leather-chain-trim-sandals">Full
                                        Width</a>
                                </li>
                                <li [class.active]="current.includes('product/masonry')">
                                    <a routerLink="/product/masonry/black-denim-dungaree-dress">Masonry Sticky Info</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="banner banner-overlay">
                            <a routerLink="/shop/sidebar/4cols">
                                <img src="assets/images/menu/banner-2.jpg" alt="Banner" width="218" height="310" />

                                <div class="banner-content banner-content-bottom">
                                    <div class="banner-title text-white">
                                        New Trends
                                        <br />
                                        <span>
                                            <strong>spring 2021</strong>
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </li>


        <li [class.active]="current.includes('/pages')">
            <a href="javascript:;" class="sf-with-ul">Rang o Riwayat</a>

            <ul>
                <li [class.active]="current.includes('pages/about')">
                    <a routerLink="/pages/about" class="sf-with-ul">About</a>
                </li>

            </ul>
        </li>
         <li [class.active]="current.includes('/pages')">
            <a href="javascript:;" class="sf-with-ul">Pages</a>

            <ul>
                <li [class.active]="current.includes('pages/about')">
                    <a routerLink="/pages/about" class="sf-with-ul">About</a>

                    <ul>
                        <li>
                            <a routerLink="/pages/about">About 01</a>
                        </li>
                        <li>
                            <a routerLink="/pages/about-2">About 02</a>
                        </li>
                    </ul>
                </li>
                <li [class.active]="current.includes('pages/contact')">
                    <a routerLink="/pages/contact" class="sf-with-ul">Contact</a>

                    <ul>
                        <li>
                            <a routerLink="/pages/contact">Contact 01</a>
                        </li>
                        <li>
                            <a routerLink="/pages/contact-2">Contact 02</a>
                        </li>
                    </ul>
                </li>
                <li [class.active]="current.includes('pages/login')">
                    <a routerLink="/pages/login">Login</a>
                </li>
                <li [class.active]="current.includes('pages/faq')">
                    <a routerLink="/pages/faq">FAQs</a>
                </li>
                <li [class.active]="current.includes('pages/404')">
                    <a routerLink="/pages/404">Error 404</a>
                </li>
                <li [class.active]="current.includes('pages/coming-soon')">
                    <a routerLink="/pages/coming-soon">Coming Soon</a>
                </li>
            </ul>
        </li>
         <li [class.active]="current.includes('/blog/')">
            <a routerLink="/blog/classic" class="sf-with-ul">Blog</a>
            <ul>
                <li [class.active]="current.includes('blog/classic')">
                    <a routerLink="/blog/classic">Classic</a>
                </li>
                <li [class.active]="current.includes('blog/listing')">
                    <a routerLink="/blog/listing">Listing</a>
                </li>
                <li [class.active]="current.includes('blog/grid')">
                    <a routerLink="/blog/grid/grid-2" class="sf-with-ul">Grid</a>
                    <ul>
                        <li [class.active]="current.includes('blog/grid/grid-2')">
                            <a routerLink="/blog/grid/grid-2">Grid 2 columns</a>
                        </li>
                        <li [class.active]="current.includes('blog/grid/grid-3')">
                            <a routerLink="/blog/grid/grid-3">Grid 3 columns</a>
                        </li>
                        <li [class.active]="current.includes('blog/grid/grid-4')">
                            <a routerLink="/blog/grid/grid-4">Grid 4 columns</a>
                        </li>
                        <li [class.active]="current.includes('blog/grid/grid-sidebar')">
                            <a routerLink="/blog/grid/grid-sidebar">Grid sidebar</a>
                        </li>
                    </ul>
                </li>
                <li [class.active]="current.includes('blog/masonry')">
                    <a routerLink="/blog/masonry/masonry-2" class="sf-with-ul">Masonry</a>
                    <ul>
                        <li [class.active]="current.includes('blog/masonry/masonry-2')">
                            <a routerLink="/blog/masonry/masonry-2">Masonry 2 columns</a>
                        </li>
                        <li [class.active]="current.includes('blog/masonry/masonry-3')">
                            <a routerLink="/blog/masonry/masonry-3">Masonry 3 columns</a>
                        </li>
                        <li [class.active]="current.includes('blog/masonry/masonry-4')">
                            <a routerLink="/blog/masonry/masonry-4">Masonry 4 columns</a>
                        </li>
                        <li [class.active]="current.includes('blog/masonry/masonry-sidebar')">
                            <a routerLink="/blog/masonry/masonry-sidebar">Masonry sidebar</a>
                        </li>
                    </ul>
                </li>
                <li [class.active]="current.includes('blog/mask')">
                    <a routerLink="/blog/mask/grid" class="sf-with-ul">Mask</a>
                    <ul>
                        <li [class.active]="current.includes('blog/mask/grid')">
                            <a routerLink="/blog/mask/grid">Blog mask grid</a>
                        </li>
                        <li [class.active]="current.includes('blog/mask/masonry')">
                            <a routerLink="/blog/mask/masonry">Blog mask masonry</a>
                        </li>
                    </ul>
                </li>
                <li [class.active]="current.includes('blog/single')">
                    <a routerLink="/blog/single/default/cras-ornare-tristique-elit." class="sf-with-ul">Single Post</a>
                    <ul>
                        <li [class.active]="current.includes('blog/single/default')">
                            <a routerLink="/blog/single/default/cras-ornare-tristique-elit.">Default with sidebar</a>
                        </li>
                        <li [class.active]="current.includes('blog/single/fullwidth')">
                            <a routerLink="/blog/single/fullwidth/fusce-pellentesque-suscipit.">Fullwidth no sidebar</a>
                        </li>
                        <li [class.active]="current.includes('blog/single/sidebar')">
                            <a routerLink="/blog/single/sidebar/utaliquam-sollicitzdvudin-leo">Fullwidth with
                                sidebar</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li [class.active]="current.includes('/elements')">
            <a routerLink="/elements" class="sf-with-ul">Elements</a>

            <ul>
                <li [class.active]="current.includes('elements/products')">
                    <a routerLink="/elements/products">Products</a>
                </li>
                <li [class.active]="current.includes('elements/typography')">
                    <a routerLink="/elements/typography">Typography</a>
                </li>
                <li [class.active]="current.includes('elements/titles')">
                    <a routerLink="/elements/titles">Titles</a>
                </li>
                <li [class.active]="current.includes('elements/banners')">
                    <a routerLink="/elements/banners">Banners</a>
                </li>
                <li [class.active]="current.includes('elements/categories')">
                    <a routerLink="/elements/categories">Product Category</a>
                </li>
                <li [class.active]="current.includes('elements/video-banners')">
                    <a routerLink="/elements/video-banners">Video Banners</a>
                </li>
                <li [class.active]="current.includes('elements/buttons')">
                    <a routerLink="/elements/buttons">Buttons</a>
                </li>
                <li [class.active]="current.includes('elements/accordions')">
                    <a routerLink="/elements/accordions">Accordions</a>
                </li>
                <li [class.active]="current.includes('elements/tabs')">
                    <a routerLink="/elements/tabs">Tabs</a>
                </li>
                <li [class.active]="current.includes('elements/testimonials')">
                    <a routerLink="/elements/testimonials">Testimonials</a>
                </li>
                <li [class.active]="current.includes('elements/blog-posts')">
                    <a routerLink="/elements/blog-posts">Blog Posts</a>
                </li>
                <li [class.active]="current.includes('elements/cta')">
                    <a routerLink="/elements/cta">Call to Action</a>
                </li>
                <li [class.active]="current.includes('elements/icon-boxes')">
                    <a routerLink="/elements/icon-boxes">Icon Boxes</a>
                </li>
            </ul>
        </li> -->
  </ul>
</nav>
