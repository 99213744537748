<footer class="footer footer-2 font-weight-normal second-primary-color"
    style="background-image: url(assets/images/home/banners/footer.jpg)">
    <div class="cta cta-horizontal cta-horizontal-box pt-5 pb-5">
        <div [ngClass]="containerClass">
            <div class="row align-items-center justify-content-center">
                <div class="col-xl-4-5col offset-xl-10col">
                    <!-- <div class="row align-items-center">
                        <div class="col-lg-5 cta-txt text-lg-left text-center">
                            <h3 class="cta-title text-white my-2 mt-0" style="color: #2871cc !important;">Join Our Newsletter asdasfsdfsdf</h3>
                            <p class="cta-desc font-size-normal second-primary-color font-weight-normal">Subcribe to get information about products and coupons</p>
                        </div>
                        <div class="col-lg-7">
                            <form action="#" class="d-flex justify-content-end justify-content-center">
                                <div class="input-group">
                                    <input type="email" class="form-control mr-0 font-weight-normal" placeholder="Enter your Email Address" aria-label="Email Adress"
                                        required>
                                    <div class="input-group-append">
                                        <button class="btn text-uppercase" type="submit">Subscribe
                                            <i class="icon-long-arrow-right mr-0"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div [ngClass]="containerClass">
        <hr class="mt-0 mb-0" style="border-color: #444;">
    </div>
    <div class="footer-middle border-0">
        <div [ngClass]="containerClass">
            <div class="row">
                <div class="col-12 col-lg-2-5cols">
                    <div class="widget widget-about mb-4">
                        <img src="assets/images/home/cybic.jpg" class="footer-logo" alt="Footer Logo" width="60"
                            height="25">
                        <!-- <p class="font-weight-normal second-primary-color">Serving Since 1993 with the mission to be the
                             choice of Generations by shaping consumers choice of quality ,
                              trust and Budget-Friendliness.“Cybic Store” is a shopping mall located in Quetta,
                               Balochistan, the heart of Pakistan.
                        </p> -->

                        <div class="widget-about-info">
                            <div class="row">
                                <!-- <div class="col-sm-6 col-md-4">
                                    <span class="widget-about-title text-white" style="color: #328CCC !important;">Got Question? Call us 24/7</span>
                                    <a href="tel:0811112229" class="text-primary">081-111-222-9</a>
                                    <span class="widget-about-title text-white" style="color: #328CCC !important;">Call Center</span>
                                    <a href="tel:03111111456" class="text-primary">03-111-111-456</a>
                                </div> -->

                                <!-- <div class="col-sm-6 col-md-8">
                                    <span class="pl-3 widget-about-title text-white" style="color: #2871cc !important;">Payment Method</span>
                                    <figure class="pl-3 mb-0 footer-payments">
                                        <img src="assets/images/payments.png" alt="Payment methods" width="272" height="20">
                                    </figure>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-5cols">
                    <div class="widget mb-4">
                        <h4 class="widget-title text-primary">Information</h4>

                        <ul class="widget-list">
                            <li><a routerLink="pages/about">About Us</a></li>
                            <li><a routerLink="pages/contact">Contact Us</a></li>
                            <li class="cursor-pointer"><a></a>Why Cybic Store</li>
                            <!-- <li><a routerLink="pages/faq">FAQ</a></li> -->
                            <!-- <li><a (click)="showLoginModal()">Log in</a></li> -->
                        </ul>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-5cols">
                    <div class="widget mb-4">
                        <h4 class="widget-title text-primary">Customer Service</h4>

                        <ul class="widget-list">
                            <!-- <li><a href="javascript:;">Payment Methods</a></li> -->
                            <!-- <li><a href="javascript:;">Money-back guarantee!</a></li> -->
                            <!-- <li><a href="javascript:;">Returns</a></li> -->
                            <li><a href="javascript:;">Shipping</a></li>
                            <li><a href="javascript:;">Terms and conditions</a></li>
                            <li><a href="javascript:;">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-sm-4 col-lg-5cols">
                    <div class="widget mb-4">
                        <h4 class="widget-title text-primary">My Account</h4>

                        <ul class="widget-list">
                            <li *ngIf="!userCheck" class="cursor-pointer"><a  (click)="showLoginModal()">Sign In</a></li>
                            <li *ngIf="userCheck" (click)="logout()" class="cursor-pointer"><a>Sign Out</a></li>
                            <li><a routerLink="/shop/cart">View Cart</a></li>
                            <li><a routerLink="/shop/wishlist">My Wishlist</a></li>
                            <li><a routerLink="/shop/dashboard" *ngIf="userCheck">Track My Order</a></li>
                            <li><a href="javascript:;">Help</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom font-weight-normal">
        <div [ngClass]="containerClass">
            <p class="footer-copyright font-weight-normal ml-lg-2 second-primary-color">Copyright © {{ year }} <a
                    href="https://alphasdigital.com/">TheAlpha'sDigital</a>. All Rights Reserved. </p>

            <!-- <ul class="footer-menu justify-content-center">
                <li>
                    <a href="javascript:;">Terms Of Use</a>
                </li>
                <li>
                    <a href="javascript:;">Privacy Policy</a>
                </li>
            </ul> -->

            <div class="social-icons social-icons-color justify-content-center">
                <span class="social-label">Social Media</span>
                <a href="https://www.facebook.com/cybicstore/" target="_blank"  class="social-icon social-facebook"
                    title="Facebook"><i class="icon-facebook-f"></i></a>
                <a href="https://twitter.com/CybicStore786" target="_blank"  class="social-icon social-twitter" title="Twitter"><i
                        class="icon-twitter"></i></a>
                <!-- <a href="https://www.instagram.com/alnaseebdryfruits/" class="social-icon social-instagram"
                    title="Instagram"><i class="icon-instagram"></i></a> -->
                <a href="https://www.youtube.com/channel/UCm-CAhPbN4-nVOQ9kOJkiUw" target="_blank" class="social-icon social-youtube"
                    title="Youtube"><i class="icon-youtube"></i></a>
                <!-- <a href="https://www.pinterest.com/AlNaseebdryfruits/" class="social-icon social-pinterest"
                    title="Pinterest"><i class="icon-pinterest-p"></i></a> -->
            </div>
        </div>
    </div>


    <div class="mb-10" *ngIf="isBottomSticky"></div>
    <br /><br />
</footer>
<div class=" footer-mobile">
    <a href="javascript:;">
        <div class="icon">
            <i class="fa-regular fa-heart"></i>
        </div>
    </a>
    <a href="javascript:;">
        <div class="icon">
            <i class="fa-regular fa-list"></i>
        </div>
    </a>
    <a href="javascript:;">
        <div class="icon">

            <i class="fa-regular fa-house"></i>
        </div>
    </a>
    <a href="javascript:;">
        <div class="icon">

            <i class="fa-regular fa-cart-shopping-fast"></i>
        </div>

    </a>
    <a href="javascript:;">
        <div class="icon">

            <i class="fa-regular fa-user"></i>

        </div>
    </a>
</div>
