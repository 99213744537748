<div class="dropdown category-dropdown">
    <a href="javascript:;" class="dropdown-toggle" title="Browse Categories" (click)="toggleMenu()">Browse
        Categories</a>

    <div class="dropdown-menu" [class.show]="!utilsService.isSticky && isHome">
        <nav class="side-nav">
            <ul class="menu-vertical sf-arrows">
                <li routerLinkActive="active">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'electronics'}">Electronics a</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'gift-idea'}">Gift Ideas</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'beds'}">Beds</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'lighting'}">Lighting</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'sofas-and-sleeper-sofas'}">Sofas &
                        Sleeper sofas</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'storage'}">Storage</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'armchairs-and-chaises'}">Armchairs &
                        Chaises</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'decoration'}">Decoration</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'kitchen-cabinets'}">Kitchen
                        Cabinets</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'coffee-and-tables'}">Coffee &
                        Tables</a>
                </li>
                <li routerLinkActive="active">
                    <a routerLink="/shop/sidebar/3cols" [queryParams]="{category: 'furniture'}">Outdoor Furniture</a>
                </li>
            </ul>
        </nav>
    </div>
</div>