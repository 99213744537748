<div class="header-search header-search-visible header-search-no-radius">
    <a href="#" class="search-toggle" role="button">
        <i class="icon-search"></i>
    </a>
    <form action="#" method="get" (click)="showSearchForm($event)" (submit)="submitSearchForm($event)">
        <div class="header-search-wrapper search-wrapper-wide" style="border-radius: 100px;">
            <div class="select-custom">
                <select id="cat" name="cat" (change)="onCatSelect($event)">
                    <option [value]=null>Food Gallery</option>
                    <option value="Hazelnuts">Hazelnuts</option>
                    <option value="Almond">Almond</option>
                    <option value="Dates">Dates</option>
                    <option value="Raisins">Raisins</option>
                    <option value="Cashew">Cashew</option>
                    <option value="Apricot">Apricot</option>
                    <option value="Walnuts">Walnuts</option>
                    <option value="Plums">Plums</option>
                    <option value="Pistachio">Pistachio</option>
                    <option value="Injeer">Injeer</option>
                    <option value="Candy & Choco">Candy & Choco</option>
                </select>
            </div>
            <label for="q" class="sr-only">Search</label>
            <input type="text" class="form-control" name="searchTerm" id="searchTerm" placeholder="What do you want to eat ..."
                required (input)="searchProducts($event)" />
            <button class="btn btn-primary" type="submit" style="border-top-right-radius: 50px;border-bottom-right-radius: 50px;">
                <span class="sr-only">Search</span>
                <i class="icon-search"></i>
            </button>
        </div>

        <div class="live-search-list" *ngIf="searchTerm.length > 2">
            <div class="autocomplete-suggestions" *ngIf="suggestions.length > 0" (click)="goProductPage()">
                <a [routerLink]="'/product/default/' + product.slug" class="autocomplete-suggestion"
                    *ngFor="let product of suggestions">
                    <figure class="mb-0 mr-3" style="width: 40px; height: 40px;">
                        <molla-image [src]="SERVER_URL + product.sm_pictures[0].url" width="40" height="40" alt="Product"
                            class="bg-transparent">
                        </molla-image>
                    </figure>

                    <div class="search-name" [innerHTML]="matchEmphasize(product.name) | safeContent"></div>
                    <span class="search-price">
                        <div class="product-price mb-0" *ngIf="product.minPrice == product.maxPrice else priceBlock">
                            ${{ product.minPrice.toFixed(2) }}</div>
                        <ng-template #priceBlock>
                            <div class="product-price mb-0" *ngIf="product.variants.length == 0 else limitBlock">
                                <span class="new-price">${{ product.minPrice.toFixed(2) }}</span>
                                <span class="old-price">${{ product.maxPrice.toFixed(2) }}</span>
                            </div>
                            <ng-template #limitBlock>
                                <div class="product-price mb-0">${{product.minPrice.toFixed(2)}} -
                                    ${{product.maxPrice.toFixed(2)}}</div>
                            </ng-template>
                        </ng-template>
                    </span>
                </a>
            </div>
        </div>
    </form>
</div>