<article class="entry blog-display">
    <figure class="entry-media" [style.padding-top]="paddingTop">
        <a [routerLink]="['/blog/single/default/' + post.slug]">
            <molla-image [src]="SERVER_URL + post.image[0].url" alt="image desc" [fixedPt]="paddingTop"></molla-image>
        </a>
    </figure>

    <div class="entry-body">
        <div class="entry-meta font-size-normal">
            <a [routerLink]="['/blog/single/default/' + post.slug]">{{ post.date | date }}</a>,&nbsp;
            <a [routerLink]="['/blog/single/default/' + post.slug]">{{ post.comments }} Comments</a>
        </div>

        <h2 class="entry-title my-4 mt-0">
            <a [routerLink]="['/blog/single/default/' + post.slug]">{{ post.title }}</a>
        </h2>

        <div class="entry-content">
            <p class="font-weight-normal mb-1">{{ post.content }}</p>
        </div>
    </div>
</article>