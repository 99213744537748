<div class="container mb-4 electronics">
  <div class="bg-lighter trending-products">
      <div class="heading-left" contentAnim aniName="blurIn">
          <h2 class="title font-weight-bold mb-1">Fabric Product	</h2>
      </div>
      <div class="position-relative">
          <ul ngbNav #nav="ngbNav" class="tab-content tab-content-carousel" [destroyOnHide]="false">
              <li ngbNavItem="best">
                  <ng-template ngbNavContent>
                      <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height" *ngIf="!loaded else elseBlock">
                          <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                      </owl-carousel>
                      <ng-template #elseBlock>
                          <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                              <molla-product-twelve [product]="product" *ngFor="let product of products | slice:0 : 15"></molla-product-twelve>
                              <div class="product d-flex flex-column overflow-hidden" routerLink="/shop/sidebar/4cols"
                              [queryParams]="{shop: 10002}" *ngIf="products.length == 15">
                              <figure class="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                                <h1 class="mx-4 px-4  mt-4 text-center"> More <span class="mx-4 px-4  mt-4"><i
                                      class="icon-arrow-right mx-4 px-4 mt-4"></i></span> </h1>
                              </figure>
                            </div>
                          </owl-carousel>
                      </ng-template>
                  </ng-template>
              </li>
              <!-- <li ngbNavItem="computers">
                  <ng-template ngbNavContent>
                      <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height" *ngIf="!loaded else elseBlock">
                          <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                      </owl-carousel>
                      <ng-template #elseBlock>
                          <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                              <molla-product-twelve [product]="product" *ngFor="let product of products | slice: 3 : 7"></molla-product-twelve>
                          </owl-carousel>
                      </ng-template>
                  </ng-template>
              </li> -->

              <!-- <li ngbNavItem="tv-video">
                  <ng-template ngbNavContent>
                      <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height" *ngIf="!loaded else elseBlock">
                          <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                      </owl-carousel>
                      <ng-template #elseBlock>
                          <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                              <molla-product-twelve [product]="product" *ngFor="let product of products | slice: 5 : 9"></molla-product-twelve>
                          </owl-carousel>
                      </ng-template>
                  </ng-template>
              </li> -->
              <!-- <li ngbNavItem="smart-home">
                  <ng-template ngbNavContent>
                      <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height" *ngIf="!loaded else elseBlock">
                          <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                      </owl-carousel>
                      <ng-template #elseBlock>
                          <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                              <molla-product-twelve [product]="product" *ngFor="let product of products | catFilter: ['smart-phones']"></molla-product-twelve>
                          </owl-carousel>
                      </ng-template>
                  </ng-template>
              </li> -->
              <!-- <li ngbNavItem="audio">
                  <ng-template ngbNavContent>
                      <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height" *ngIf="!loaded else elseBlock">
                          <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                      </owl-carousel>
                      <ng-template #elseBlock>
                          <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                              <molla-product-twelve [product]="product" *ngFor="let product of products | catFilter: ['audio']"></molla-product-twelve>
                          </owl-carousel>
                      </ng-template>
                  </ng-template>
              </li> -->
              <!-- <li ngbNavItem="theater">
                  <ng-template ngbNavContent>
                      <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height" *ngIf="!loaded else elseBlock">
                          <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                      </owl-carousel>
                      <ng-template #elseBlock>
                          <owl-carousel [options]="sliderOption" carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                              <molla-product-twelve [product]="product" *ngFor="let product of products" ></molla-product-twelve>
                          </owl-carousel>
                      </ng-template>
                  </ng-template>
              </li>   -->
          </ul>
          <div [ngbNavOutlet]="nav"></div>
          <ul ngbNav #nav1="ngbNav" class="tab-content tab-content-carousel" #tab [destroyOnHide]="false">
            <li ngbNavItem="best">
                <ng-template ngbNavContent>
                    <owl-carousel [options]="sliderOption"
                        carouselClasses="electronic-carousel owl-simple carousel-equal-height"
                        *ngIf="!loaded else elseBloc">
                        <div class="skel-pro" *ngFor="let item of [1,2,3,4,5]"></div>
                    </owl-carousel>
                    <ng-template #elseBloc>
                        <owl-carousel [options]="sliderOption"
                            carouselClasses="electronic-carousel owl-simple carousel-equal-height">
                            <molla-product-twelve [product]="product"
                                *ngFor="let product of otherproducts | slice:0 : 15"></molla-product-twelve>
                            <div class="product d-flex flex-column overflow-hidden" routerLink="/shop/sidebar/4cols"
                                [queryParams]="{shop: 0}" *ngIf="products.length == 15">
                                <figure
                                    class="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                                    <h1 class="mx-4 px-4  mt-4 text-center"> More <span class="mx-4 px-4  mt-4"><i
                                                class="icon-arrow-right mx-4 px-4 mt-4"></i></span> </h1>
                                </figure>
                            </div>
                        </owl-carousel>
                    </ng-template>
                </ng-template>
            </li>

        </ul>
          <div [ngbNavOutlet]="nav1" *ngIf="otherproducts.length > 0"> </div>
            <div class="bottom-end-btn">
                <button class="btn-product text-uppercase text-dark text-decoration-none"
                routerLink="/shop/sidebar/4cols" [queryParams]="{shop: 10002}" >More</button>
            </div>
          <!-- <div class="banner banner-overlay mb-0 mt-2 mt-md-0 bg-image position-md-relative position-absolute" style="background-image: url(assets/images/home/banners/banner-bg-1.jpg)">
              <span class="product-label label-sale text-uppercase">Best Deal</span>
              <div class="banner-content position-relative d-flex flex-column">
                  <h3 class="banner-title font-weight-bold mb-2 text-left">Apple Watch
                      <br />Starting at $199</h3>
                  <a routerLink="/shop/sidebar/4cols" class="btn-product text-uppercase text-dark text-decoration-none btn-rounded" title="Discover now">
                      <span class="text-dark border-0">Discover Now&nbsp;
                          <i class="icon-long-arrow-right d-inline-block"></i>
                      </span>
                  </a>
              </div>
          </div> -->
      </div>
  </div>
</div>
