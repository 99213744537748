import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { CategoryDto } from 'src/app/shared/models/category';
import { CategoryService } from 'src/app/shared/services/category.service';

import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'molla-category-menu',
	templateUrl: './category-menu.component.html',
	styleUrls: ['./category-menu.component.scss']
})

export class CategoryMenuComponent implements OnInit, OnDestroy {

	isHome = true;
	categoryList :CategoryDto[] =[];
	private subscr: Subscription;

	constructor(public utilsService: UtilsService, public router: Router , public categoryService :CategoryService) {
		this.subscr = this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.isHome = event.url === "/";
			} else if (event instanceof NavigationEnd) {
				this.isHome = event.url === "/";
			}
		})
	}

	ngOnInit() {
		this.loadAllCategory();

	}
	loadAllCategory(){

		this.categoryService.getAllCategory().subscribe(res=>{

			// if (environment.showLogs) {
			// 	console.log(res);
			// }
			this.categoryList =[];
			if (res.successFlag) {
				this.categoryList.splice(0, this.categoryList.length);
				this.categoryList.push(...res.data);
			}
		}, error => {
			// if (environment.logErrors) {
			// 	console.log(error);
			// }
		})
	}
	ngOnDestroy(): void {
		this.subscr.unsubscribe();
	}

	toggleMenu() {
		document.querySelector('.category-dropdown .dropdown-menu').classList.toggle('show');
	}
}
