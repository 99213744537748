import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { tabSlider } from '../data';

@Component({
  selector: 'molla-febric-collection',
  templateUrl: './febric-collection.component.html',
  styleUrls: ['./febric-collection.component.scss']
})
export class FebricCollectionComponent implements OnInit {


	// posts = [];
	// topProducts = [];
	// newProducts = [];
	// elecProducts = [];
  @Input() otherproducts = [];
	items =[1,2,3,4];
  	sliderOption = tabSlider;
  @Input() products = [];
	@Input() loaded = false;
  constructor(public apiService: ApiService,public categoryService :CategoryService, public utilsService: UtilsService, private modalService: ModalService,) {
    // const newLocal = this;
    // newLocal.modalService.openNewsletter();

		// this.apiService.fetchHomeData().subscribe(result => {
		// 	this.products = result.products;
		// 	this.posts = result.blogs;
		// 	this.topProducts = utilsService.attrFilter(result.products, 'top');
		// 	this.newProducts = utilsService.attrFilter(result.products, 'new');
		// 	this.elecProducts = utilsService.catFilter(result.products, ['electronics']);
		// 	this.loaded = true;
		// })
  }

  ngOnInit(): void {
    // this.loadAllProduct();
  }
//   loadAllProduct(){
//
// 		this.categoryService.getAllCategory().subscribe(res=>{
//

// 			this.products =[];
// 			if (res.successFlag) {
// 				this.products.splice(0, this.products.length);
// 				this.products.push(...res.data);
// 			}
// 		}, error => {
// 			// if (environment.logErrors) {
// 			// 	console.log(error);
// 			// }
// 		})
// 	}
}
