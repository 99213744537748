import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../services/category.service';

@Component({
  selector: 'molla-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  categoryList = [];

  constructor(public categoryService: CategoryService) {
    this.categoryService.getAllCategory().subscribe(res => {

      if (res.successFlag) {
        this.categoryList = [];
        this.categoryList.splice(0, this.categoryList.length);
        this.categoryList.push(...res.data);
      }
    });
  }

  ngOnInit(): void {
  }

}
